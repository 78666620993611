::-webkit-input-placeholder { /* Edge */
  color: #fff !important;
  opacity: 1;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #fff !important;
  opacity: 1;
}

::placeholder {
  color: #fff !important;
  opacity: 1;
}
.search-input {
  min-height: 300px;
  margin: 150px auto 100px;
  text-align: center;
}
.searchInner {
  max-width: 850px;
  margin: auto;
  background: #1b1b1b;
  padding: 15px;
  border-radius: 10px;
}
.searchInner .ui.input {
  width: calc(100% - 340px);
}
.searchIcon {
  background: linear-gradient(90.24deg, #FA2CAB -1.6%, #9B69FC 99.79%), #FA2CAB;
  border: none;
  color: #fff;
  padding: 9px 31px !important;
  border-radius: .28571429rem;
}
.searchInner .ui.input input  {
  background: #131313;
  color: #fff;
}
.ui.selection.dropdown {
  background: #FA2CAB !important; 
  color: #fff;
  border: 1px solid #FA2CAB;
  margin-right: 10px;
}
.searchInner  .ui.active.search.dropdown input.search:focus + .text {
  color: #fff !important;
}
@mixin mobile {
  @media (max-width: 900px) { @content; }
}
@mixin tablet {
  @media (min-width: 901px) and (max-width: 1199px) { @content; }
}
@mixin desktop {
  @media (min-width: 1200px) { @content; }
}
@mixin lg_desktop {
  @media (min-width: 1800px) { @content; }
}
.hero {
  background-image: url("/assets/images/fansbg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-position-y: top;
  height: 868px;
  width: 100%;
  padding: 0 50px;
  margin-top: -160px;
  margin-bottom: 60px;

  position: relative;
  overflow: hidden;
  @include mobile {
    background-position: center;
    background-size: cover;
    height: 500px;
    padding: 20px;
  }
  .heroVideo {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .heading {
    position: relative;
    bottom: 0;
    width: 50%;
    margin-top: 30vh;
    @include mobile {
      width: 100%;
      margin-top: 20vh;
      padding-bottom: 20vh;
    }
    h3 {
      width: fit-content;
      font-weight: 800;
      font-size: 66px;
      line-height: 74px;
      letter-spacing: -0.03em;
      color: #fff0fa;
      @include mobile {
        font-size: 36px;
        line-height: 44px;
      }
    }
    span {
      display: block;
      //background: -webkit-linear-gradient(358deg, #e659d6, #8877ea, #86e2ee);
      //-webkit-background-clip: text;
      //-webkit-text-fill-color: transparent;
    }
    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: #ffffff;
      opacity: 0.9;
      @include mobile {
        font-size: 16px;
      }
    }
  }
  .button {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    width: 209px;
    height: 56px;
    color: #02001F;
    text-decoration: none;
    background-color: #59D6E6;
    border: 1px solid #59D6E6;
    border-radius: 12px;

    margin-top: 40px;
    transition: ease-in-out 0.25s;
    .icon {
      font-size: 28px;
    }
    &:hover {
      color: #02001F;
      background-color: #33919e;
    }
    @include mobile {
      width: 100%;
    }
  }
}


.section {
  text-align: center;
  margin-top: 100px;
  //padding-bottom: 200px;
  h3 {
    color: #fff;

    font-weight: 600;
    font-size: 36px;
    line-height: 44px;

    margin-top: 20px;
    margin-bottom: 60px;
  }
  b {
    color: #59D6E6;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
  }
  &__one {
    background-image: url("../../../staticAssets/images/home/background01.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    //height: 700px;
    padding: 0 0 100px 0;
    @include mobile {
      padding: 0 0 50px 0
    }
  }
  &__cta {
    background-image: url("/assets/images/inloop02.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    padding: 200px 0;
    img {
      border: 1px solid rgba(255, 255, 255, 0.4);
      border-radius: 8px;
    }
    @include mobile {
      background-size: cover;
      .column {
        width: 100% !important;
        margin: auto;
        padding: 10px;
        .content {
          text-align: center;
          margin: auto;
          img {
            margin: auto;
            text-align: center;

            border: 1px solid rgba(255, 255, 255, 0.4);
            border-radius: 8px;
          }
        }
      }
    }
    @include mobile {
      padding: 50px 0;
    }
    .heading {
      height: 50rem;

      background-image: url("/assets/images/inloop01.svg");
      background-repeat: no-repeat;
      background-position: top;
      background-size: cover;
      padding: 100px 0;
      
      text-align: center;
      h3 {
        color: #fff;
        font-weight: 600;
        font-size: 48px;
        line-height: 56px;
        letter-spacing: -0.03em;

        margin-bottom: 20px;
      }
      p {
        width: 500px;

        color: #fff;
        font-weight: 300;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: -0.03em;

        margin: auto;
        @include mobile {
          width: 100% !important;
          padding: 0 10px;
        }
      }
    }
    .content {
      margin-top: -100px !important;
      margin-bottom: -150px !important;
      .column {
        .content {
          margin-top: 0px !important;
          margin-bottom: 50px !important;
        }
      }
    }
    .footer {
      height: 50rem;
      background-image: url("/assets/images/inloop03.svg");
      background-repeat: no-repeat;
      background-position: bottom;
      background-size: cover;
      padding: 100px 0;

      position: relative;

      text-align: center;
      @include mobile {
        height: 25rem;
      }
      button {
        position: absolute;
        bottom: 12rem;
        left: 0;
        right: 0;

        width: 200px !important;
        padding: 22px 0px;
        margin: auto;

        color: #887DEA;
        background: #02001F;
        border: 1.5px solid #887DEA;
        border-radius: 12px;
        &:hover {
          color: #fff;
          background: #887DEA;
        }
        @include mobile {
          bottom: 10vh;
        }
      }
    }
  }
  
  width: 100%;
  //padding: 0 50px 200px 50px;
  //padding-bottom: 100px;
  @include mobile {
    padding: 20px;
    .break {
      display: none !important;
    }
  }
  .services {
    display: flex;
    justify-content: space-between;
    gap: 80px;

    text-align: center;
    //padding: 100px 0;
    .service_item {
      padding: 40px;
      background: rgba(255, 255, 255, 0.04);
      border-radius: 16px;
    }
    @include mobile {
      display: block;
      .service_item {
        margin-bottom: 40px;
        padding-bottom: 60px;
        background: rgba(255, 255, 255, 0.04);
        border-radius: 16px;
      }
    }
    div {
      width: 100%;
    }
    .serviceImage {
      width: 56px !important;
      margin: auto;
    }
    .content {
      padding: 20px 0;
      h4 {
        font-weight: 600;
        font-size: 26px;
        line-height: 32px;
        font-feature-settings: "cv05" on, "cv11" on;
        color: #f6f5ff;
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #999cb6;
      }
      @include mobile {
        text-align: center;
      }
    }
    a {
      color: #887dea;
      text-decoration: none;
      &:hover {
        color: #6359c1;
      }
    }
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 209px;
      height: 56px;
      color: #887dea;
      background-color: transparent;
      border: 1px solid #887dea;
      border-radius: 12px;
      transition: ease-in-out 0.25s;
      .icon {
        font-size: 28px;
      }
      &:hover {
        color: #fff;
        background-color: #887dea8f;
      }
      @include mobile {
        width: 100%;
      }
    }
  }

  .banner {
    background-image: url("/assets/images/marketbg_01.png") !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    height: 500px;
    border-radius: 24px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;

    
    text-align: left;

    padding: 50px !important;
    margin: 100px auto;
    @include mobile {
      height: auto;
      padding: 25px !important;
      margin: 50px auto;
    }
    .qrcode {
      width: 70px;
      border-radius: 14px;
      padding: 5px;
      background-color: #fff;
      @include mobile {
        display: none;
      }
    }
    .left {
      width: 30%;
      @include mobile {
        width: 100%;
      }
      @include tablet {
        width: 100%;
      }
    }
    .right {
      @include mobile {
        display: none;
      }
      @include tablet {
        display: none;
      }
    }
    .content {
      margin: 50px 0;
      h4 {
        font-weight: 700;
        font-size: 48px;
        line-height: 49px;
        letter-spacing: -0.03em;
        color: #ffffff;
        @include mobile {
          font-size: 32px;
          line-height: 30px;
        }
      }
      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: #ffffff;
        opacity: 0.9;
        @include mobile {
          font-size: 16px;
          line-height: 18px;
        }
      }
    }
    .buttons {
      display: flex !important;
      gap: 20px;
      @include mobile {
        display: block;
      }
      img {
        transition: ease-in-out 0.2s;
        @include mobile {
          width: 70%;
          margin: auto;
          padding: 10px 0;
        }
        &:hover {
          transform: scale(0.95);
        }
      }
    }
  }
}


.discover {
  background-image: url("/assets/images/fansbg02.png");
  background-repeat: no-repeat;
  //background-position: center;
  background-size: 100% 100%;
  width: 100%;
  //height: 970px;
  padding: 200px 50px 100px 50px;
  text-align: center;
  //margin: 100px 0;
  @include mobile {
    padding: 10px;
    .column {
      width: 100% !important;
    }
  }
  @include tablet {
    padding: 20px 0;
    .column {
      width: 50% !important;
    }
  }
  b {
    color: #59D6E6;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
  }
  .container {
    max-width: 1440px !important;
    margin: auto;
  }

  h3 {
    padding: 20px;
    font-family: "Lexend";
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #fff0fa;
    span {
      background: -webkit-linear-gradient(358deg, #e659d6, #8877ea, #86e2ee);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    @include mobile {
      padding: 0;
      font-size: 28px;
      line-height: 24px;
    }
  }
  .featured_nft_image {
    width: 200px;
  }
  .swiper-container {
    padding-top: 55px;
    padding-bottom: 55px;
  }
  .swiper-pagination-bullet {
    width: 18px;
    height: 18px;
    display: inline-block;
    border-radius: 50%;
    background: rgba(136, 124, 235, 0.15);
    border: 2px solid #887ceb;
    opacity: 0.2;
  }
  .swiper-pagination-bullet-active {
    opacity: 1;
    background: #887ceb;
    border: 1px solid #887ceb;
  }
  .swiper-slide {
    border: 1px solid rgba(255, 255, 255, 0.12) !important;
    border-radius: 24px;
    transform: scale(0.8);
    transition: ease-in-out 0.5s;
  }
  .swiper-slide-active {
    border: 1px solid rgba(255, 255, 255, 0.12) !important;
    transform: scale(1);
    transition: ease-in-out 0.5s;
  }
  .swiper-button-next,
  .swiper-button-prev {
    color: #fff !important;
    background-image: none !important;
    width: 0 !important;
    height: 50px !important;
    &::after {
      padding: 15px;
      font-size: 25px;
      font-weight: 800;
      //background: rgba(255, 255, 255, 0.1);
      //border: 1px solid rgba(255, 255, 255, 0.08);
      border-radius: 40px;
    }
  }
  .swiper-container {
    background: transparent !important;
    border-color: transparent;
  }
  .collection {
    width: 100%;
    height: 519px;
    width: 532px;
    background-color: #000 !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0), 0 0 0 1px rgba(0, 0, 0, 0);
    border-radius: 24px;
    @include mobile {
      height: 519px;
      width: 100%;
    }
    .collection_details {
      text-align: center;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0px;

      padding: 20px;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.8) 20%,
        rgba(14, 14, 24, 0.6),
        70%,
        rgba(23, 23, 40, 0) 100%
      );
      border-radius: 24px !important;
      .project_image img {
        max-width: 60px;
        border-radius: 50px;
      }
      p {
        color: #fff;
        span {
          color: rgba(246, 245, 255, 0.6);
          font-weight: 400 !important;
          font-size: 14px !important;
          line-height: 17px !important;
          text-align: center !important;
        }
      }
      .nft_button {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #887cec;
      }
    }
  }
}
.addVideo {
  position: relative;
  overflow: hidden;
  margin-right: .5rem;
  &:hover {
    cursor: pointer;
  }
}

.addVideo>button {
  background: transparent;
  border: none;
  color: var(--green);
  padding: 0;
  z-index: 100000;
  font-size: 1.5em;
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
}

.addVideo .video-input-container {
  // height: 100%;
  // width: 15vw;
  // position: absolute;
  // bottom: 100%;
  // right: 5%;
  // input {
  //   background: transparent;
  //   color: #4DD4B6;
  //   border: none;
  //   padding: 0.5em .1em;
  //   border-radius: 6px;
  //   border: 1px solid;
  // }
}

.video-input-container {
  // height: 100%;
  // width: 15vw;
  // position: absolute;
  // bottom: 100%;
  // right: 5%;
  display: flex;
  button {
    border: 1px solid var(--green);
    color: var(--green);
    font-weight: normal;
    background-color: transparent;
    margin-left: 1em;
    border-radius: 6px;
    &:focus {
      outline: none;
    }
    &:hover {
      color: var(--background-dark);
      background-color: var(--green);
      cursor: pointer;
    }
  }
  input {
    background: transparent;
    color: var(--green);
    border: none;
    padding: 0.5em .3em;
    border-radius: 6px;
    border: 1px solid;
    &:focus {
      outline: none;
    }
  }
}

// .addVideo input {
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   opacity: 0;
//   &:hover {
//     cursor: pointer;
//   }
// }
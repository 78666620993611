.RichEditor-root {
  background: transparent;
  border: 1px solid var(--icon-secondary);
  border-radius: 3px;
  font-size: 14px;
  padding: 15px;
}

.RichEditor-editor {
  border-top: 1px solid var(--icon-secondary);
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
  a {
    color: var(--green);
  }
  blockquote:after {
    content: '\2760';
    margin: 0 .5em;
  }
  blockquote:before {
    content: '\275D';
    margin: 0 .5em;
  }
  .input {
    height: 354px;
    width: 220px;
    padding: 0 12px;
    font-size: 15px;
    font-family: inherit;
    background-color: var(--background-dark);
    border: none;
    color: var(--button-light);
  }
  .input:focus {
    outline: none;
  }
  .input::placeholder {
    color: var(--icon-secondary);
  }
  .inputInvalid {
    color: var(--red);
  }
  .link {
    color: var(--green);
    text-decoration: underline;
  }
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 500px;
  .public-DraftStyleDefault-ltr {
    text-indent: 2em;
  }
}

.RichEditor-editor .RichEditor-blockquote {
  display: flex;
  font-family: 'Lexend', 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
  color: var(--text-color);
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: var(--back-color);
  font-family: 'Lexend', 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 10px 20px;
}

.RichEditor-controls-wrapper {
  display: flex;
  justify-content: space-between;
  .media-controls {
    display: flex;
    &:hover {
      cursor: pointer;
    }
  }
}

.RichEditor-controls {
  font-family: 'Lexend', 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: var(--icon-secondary);
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-styleButton:last-child {
  margin-right: 0;
}

.RichEditor-activeButton {
  color: var(--green);
}

.RichEditor-link {
  color: var(--green);
}

/*      linkify plugin       */

.draftJsLinkifyPlugin__link__2ittM,
.draftJsLinkifyPlugin__link__2ittM:visited {
  color: var(--green);
  text-decoration: none;
}

.draftJsLinkifyPlugin__link__2ittM:hover,
.draftJsLinkifyPlugin__link__2ittM:focus {
  color: var(--green);
  opacity: 0.85;
  outline: 0;
  /* reset for :focus */
  cursor: pointer;
}

.draftJsLinkifyPlugin__link__2ittM:active {
  color: var(--green);
}

/*    inline toolbar plugin     */

.draftJsToolbar__buttonWrapper__1Dmqh {
  display: inline-block;
}

.draftJsToolbar__button__qi1gf {
  background: var(--header-back);
  color: var(--icon-secondary);
  font-size: 18px;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px;
}

.draftJsToolbar__button__qi1gf svg {
  fill: var(--icon-secondary);
}

.draftJsToolbar__button__qi1gf:hover,
.draftJsToolbar__button__qi1gf:focus {
  background: var(--header-back);
  color: var(--green);
  outline: 0;
  cursor: pointer;
  /* reset for :focus */
}

.draftJsToolbar__active__3qcpF {
  background: var(--header-back);
  color: var(--green);
}

.draftJsToolbar__active__3qcpF svg {
  fill: var(--green);
}

.draftJsToolbar__separator__3U7qt {
  display: inline-block;
  border-right: 1px solid var(--button-light);
  height: 24px;
  margin: 0 0.5em;
}

.draftJsToolbar__toolbar__dNtBH {
  left: 50%;
  -webkit-transform: translate(-50%) scale(0);
  transform: translate(-50%) scale(0);
  position: absolute; //border: 1px solid #ddd;
  // background: #fff;
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px var(--header-back);
  z-index: 2;
  box-sizing: border-box;
}

.draftJsToolbar__toolbar__dNtBH:after,
.draftJsToolbar__toolbar__dNtBH:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.draftJsToolbar__toolbar__dNtBH:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: var(--header-back);
  border-width: 4px;
  margin-left: -4px;
}

.draftJsToolbar__toolbar__dNtBH:before {
  border-color: rgba(221, 221, 221, 0);
  border-top-color:var(--header-back);
  border-width: 6px;
  margin-left: -6px;
}

/*      anchor plugin     */

.draftJsMentionPlugin__input__1Wxng {
  height: 34px;
  width: 220px;
  padding: 0 12px;
  font-size: 15px;
  font-family: inherit;
  background-color: var(--header-back);
  border: none;
  color:var(--text-color);
}

.draftJsMentionPlugin__input__1Wxng:focus {
  outline: none;
}

.draftJsMentionPlugin__input__1Wxng::-webkit-input-placeholder {
  color: var(--icon-secondary);
}

.draftJsMentionPlugin__input__1Wxng:-ms-input-placeholder {
  color: var(--icon-secondary);
}

.draftJsMentionPlugin__input__1Wxng::placeholder {
  color: var(--icon-secondary);
}

.draftJsMentionPlugin__inputInvalid__X9hHv {
  color: var(--red);
}

.draftJsMentionPlugin__link__TQHAX {
  color: var(--green);
  text-decoration: underline;
}

.draftJsMentionPlugin__mentionSuggestions__2DWjA{
  background-color: var(--page-back)!important;
  border-color: var(--page-back)!important;
  box-shadow: 0px 4px 30px 0px var(--back-color)!important;
}

.draftJsMentionPlugin__mentionSuggestionsEntry__3mSwm:active,
.draftJsMentionPlugin__mentionSuggestionsEntryFocused__3LcTd {
  background-color: var(--back-color)!important;
}
.draftJsMentionPlugin__mention__29BEd,
.draftJsMentionPlugin__mention__29BEd:visited,
.draftJsMentionPlugin__mention__29BEd:hover,
.draftJsMentionPlugin__mention__29BEd:focus{
  background: transparent!important;
  color: inherit!important;
}

// .draftJsMentionPlugin__iframe__stjRT {
//   width: 100%;
//   height: 500px;
// }

blockquote {
  quotes: "\201C" "\201D" "\2018" "\2019";
}

blockquote:before {
    content: open-quote;
}
blockquote:after {
    content: close-quote;
}
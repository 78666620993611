.article_tab ul li, .approveHeading_inner {
	cursor: pointer;
}

.bounty_card_footer{
	position: absolute;
	bottom: 205px;
	margin: auto !important;
	font-size: 14px;
	font-weight: bolder;
}

.bounty_card_footer_right {
	width: 150px;
	display: inline-block;
	position: absolute;
	bottom: 226px;
	margin: auto !important;
	font-size: 14px;
	font-weight: bolder;
	right: 15px;
	text-align: right;
}

.bounty_card_footer_right .bounty_reward{
	float: right;
}

.bounty_reward {
	margin: auto !important;
	font-size: 14px;
	color: #000;
	font-weight: normal;
	background: #01CDFD;
	padding: 1px 9px;
	border-radius: 3px;
}

.bounty_reward span{
	font-weight: bolder;
}
.bounty_status {
	color: #BBBBBB;
	right: 25px;
	margin: auto !important;
	font-size: 14px;
	font-weight: bolder;
	padding: 1px 9px;
}
.cz-bountyPage {
	// margin-top: 140px;
	width: 85%;
	//margin-left: auto;
	//margin-right: auto;
}
.cz-bountyPage.single {
	// margin-top: 140px;
	width: 70%;
	margin-left: auto;
	margin-right: auto;
}
.content-wrapper.cz-bountyPageWrap {
	width: calc(90% - 90px) !important;
	// padding-left: 135px;
	margin: 0 auto;
}
.bountyHeading{
  	display: flex;
		align-items: flex-end;
  	justify-content: space-between;
  	gap:20%;
		margin-bottom: 30px;
  h1{
    font-size: 33px;color: white;
  }
  span{
    font-weight: 400;text-transform: uppercase;
  }
}

.approveHeading{
  span{
    color:white;font-weight: 400;
  }
  h1{
    font-size: 33px;color: white;
    span{
      font-weight: 400;
    }
  }
}

.bounty_event {
	list-style: none;
	margin: 4px;
	font-weight: bolder;
	cursor: pointer;
	margin-left: 0 !important;
	padding: 0 !important;
	li {
		display: inline-block;
		padding: 0 10px;
		color: #fff;
	}
	.active{
		color: var(--pink)
	}
}

.bountySubmitArticle {
	width: max-content;
	border-radius: 5px;
	background-color: #c83e93;
	color: #000;
	padding: 0px 30px !important;
	font-size: 14px;
	border: 0;
	text-transform: uppercase;
	font-weight: 500;
	line-height: 40px !important;
}
.bountySubmitArticle img {
	margin-left: 5px;
}

.createCompetitionArticle {
	width: 100% !important;
	max-width: 893px;
	margin: 0px auto 100px !important;
	// background: #1B1B1B;
  border-radius: 15px;
}
.createCompetitionArticle .column.post-header {
	padding: 20px 15px;
	border-radius: 15px 15px 0 0;
}
.createCompetitionArticle .column .post-date {
	width: 100%;
padding: 20px 0px;
}
.createCompetitionArticle .column .post-date .ui.divider {
	display: none;
}
.createCompetitionArticle .column .post-date .post-author p {
	font-size: 17px;
	font-weight: 300;
	margin: 0;
	width: 100%;
	display: flex;
	align-items: center;	
}
.createCompetitionArticle .column .post-date .post-author p .user_name {
	float: left;
}
.createCompetitionArticle .column .post-date .post-author .text-author {
	width: 100%;
}
.createCompetitionArticle .btnWrap img.left_btn {
	transform: rotate(180deg);
}
.createCompetitionArticle .btnWrap .bountyEditArticle {
	float: left;
}
.createCompetitionArticle .btnWrap img.left-btn {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
	margin-right: 10px;
	margin-left: 0;
}
.createCompetitionArticle .column .post-date .post-author p .time-txt {
	color: #fff !important;
	font-size: 17px;
	font-weight: 300;
	margin: 0;
}
.createCompetitionArticle .column .post-date .post-author p .image {
	width: 46px;
  margin-right: 15px;
	height: 46px;
	border-radius: 100%;
	border: 0;
}

.bountisImg img{
	object-fit: cover;
}
.createCompetitionArticle .column .post-date .post-author p span.dot {
	margin: 0 10px;
	text-align: center;
	display: inline-block;
	width: 5px;
	height: 5px;
	background: #919191;
	font-size: 0;
	border-radius: 100%;
	border: 0;
	line-height: 16px;
}
.createCompetitionArticle .column .post-date .post-author p .time-txt img {
	width: 16px;
	margin-right: 5px;
}
.createCompetitionArticle .column.post-header {
	padding: 20px 30px 0 30px;
	justify-content: unset;
	display: block;
	background: #1b1b1b;
	margin: 0;
}
.bountisImg img{
	object-fit: cover;
}
.createCompetitionArticle .column.post-header h3 {
	width: auto;
	display: flex;color: #ffffff;
	align-items: center;
	font-family: 'Lexend', 'Times New Roman', sans-serif !important;
    font-weight: normal;
}
.createCompetitionArticle .column.post-header .post-header__visual span {
	background: #131313;
	border-radius: 30px;
	font-size: 12px;
	font-weight: 400;
	margin-left: 10px;
	color: #919191;
	padding: 10px 20px;
}
.createCompetitionArticle .column.post-header h3 input, .createCompetitionArticle .column .post-header h3 {
  width: 100% !important;
  max-width: 300px;    
	color: #919191 !important;
	font-family: 'Lexend', 'Times New Roman', sans-serif !important;
    font-weight: normal !important;
}
.createCompetitionArticle .column.post-header .btn_box {
	margin-top: 10px;
}
.createCompetitionArticle .column.post-header .btn_box li {
	display: inline-block;margin-right: 10px;
}
.createCompetitionArticle .column.post-header .btn_box ul {
	padding: 0;margin: 0;
	list-style: none;
}
.createCompetitionArticle .column.post-header .btn_box li a {
	display: inline-block;
	background: #2D2D2D;
	text-decoration: none;
	font-weight: 400;
	padding: 5px 17px;
	border-radius: 5px;
	text-transform: capitalize;
	color: #ffffff;
}

.createCompetitionArticle .column.post-header .btn_box li a.ppl {
	background: #C83E93;
}
.createCompetitionArticle .img-editor {
	background: #171717 !important;
	width: auto !important;
	padding: 16px 16px !important;
}
.createCompetitionArticle .img-editor .upload-link {
	background: #171717;
}
.createCompetitionArticle .img-editor .post-featured-image {
	max-width: 100%;
}
.createCompetitionArticle .textEditor .cz-textarea-setting {
	height: 100% !important;
}

.textEditor{
  width:50%;
  margin:auto;
}
.cz-bountyPage .start-learning-card {
  margin-top: 40px !important;
  padding: 0 !important;
}
.cz-bountyPage .start-learning-card .card {
	width: 100% !important;height: 100%;
	box-shadow: none !important;
}
.cz-bountyPage .feedSwiper {
	height: 500px;
 .swiper-slide {
		height: 450px;
		border-radius: 15px;
		padding: 10px 0 0;
	}
}
.createCompetitionArticle .textEditor {
	width: 100%;
}
.createCompetitionArticle .textEditor .fr-toolbar {
	background: #000000 !important;
	border-radius: 0;
	border: 0;
}
.createCompetitionArticle .textEditor .fr-toolbar .fr-newline {
	display: none;
}
.createCompetitionArticle .textEditor .fr-toolbar svg path {
	fill: #fff !important;
}
.createCompetitionArticle .textEditor .fr-wrapper .fr-element.fr-view {
	min-height: 400px !important;color: #fff;
}

.createCompetitionArticle .textEditor .fr-wrapper {
	border: 0 !important;
	height: auto !important;
	background: rgba(0, 0, 0, 0.2) !important;
	background-blend-mode: multiply !important;
}

.createCompetitionArticle .textEditor .btnWrap,.createCompetitionArticle .btnWrap {
	padding: 20px 20px;margin: 0;
	text-align: right;
	background: #1B1B1B;
	width: 100%;
	border-radius: 0 0 20px 20px;
	display: inline-block;
}
.createCompetitionArticle .post-categories-n-tags {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 0 auto;
	// margin-top: 15px;
}
.content-wrapper.cz-bountyPageWrap.Bounty_competitionsArticle .createCompetitionArticle {
	max-width: 100%;
	margin-bottom: 30px !important;
	margin-top: 100px !important;
}
.approveHeading {
	width: 100%;
	margin-bottom: 12px;
}
.competitionArticle .row {
	padding: 25px 15px  !important;
}
.competitionArticle .column {
	width: 50% !important;
	padding: 0px 10px !important;
}
.competitionArticle .row {
	margin: 0;
	background: rgba(0,0,0,0.2);
	border-radius: 15px 15px 0 0;
}
.competitionArticle {
	background: transparent;
}
.competitionArticle .post-categories-n-tags .ui.container.meta-data {
	margin: 0 auto !important;
	padding: 0;
}
.competitionArticle .column .btnWrap {
	background: #171717;
	border-radius: 10px;
	text-align: center;
	min-height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.competitionArticle .column .btnWrap .bountySubmitArticle {
	background: rgba(45, 45, 45, 0.35);
	font-size: 14px;
	color: #ffffff;
	font-weight: normal;
	text-decoration: underline;
	text-transform: capitalize;
	padding: 10px 26px !important;
}
// .createCompetitionArticle .post-question-n-tags {
// 	margin-top: 15px;
// }
.competitionArticle .categories-control {
	background: transparent !important;
}
.competitionArticle .categories-control .accordion-wrapper {
	background: #171717 !important;margin-bottom: 5px !important;
}
.competitionArticle .categories-control .search .input {
	margin-top: 0;
	margin-bottom: 5px;
}
.competitionArticle .categories-control .search .prompt {
    background: #1A1A1F !important;
}
.competitionArticle .categories-control .prompt {
	border: none !important;
	background: #171717 !important;
	padding: 1rem 1.2rem 1rem 1rem;
	border-radius: 8px;
}
.createCompetitionArticle .column .post-date .post-author {
	display: flex !important;
	align-items: center !important;
}
.createCompetitionArticle .column .post-date .post-author .img-author {
	width: 55px;
	height: auto;
	text-align: center;
	margin-right: 15px;
}
.createCompetitionArticle .column .post-date .post-author .img-author .image {
	width: 46px;
	height: auto;
}
.createCompetitionArticle .column .post-date .post-author img {
	width: 46px;
	height: 100%;
}
.post-categories-n-tags .ui.checkbox.accordion-checkbox label {
	padding-left: 0;
	color: white;
	font-size: 14px;
	font-weight: normal;
}
.createCompetitionArticle .post-categories-n-tags .ui.checkbox.accordion-checkbox label:before, 
.createCompetitionArticle .post-categories-n-tags .ui.checkbox.accordion-checkbox label:after {
	right: 0;
	left: auto;
	border: 2px solid #919191 !important;
}
.categories-control .ui.input {
	width: 100%;
	margin: 0 0 5px;
}

.createCompetitionArticle .cz-post-header-top .post-featured-image {
	width: 100%;
	max-width: 100%;
	border-radius: 0 !important;
	//height: 440px;
}
.createCompetitionArticle .cz-post-header-top .post-featured-image img.ui.image {
	border-radius: 0;
	width: 100%;
	height: 100%;
	object-fit: contain;
	background: rgba(0, 0, 0, 0.2) !important;
	object-position: top;
}
.createCompetitionArticle .cz-set-set {
	margin: 0;
}
.createCompetitionArticle .cz-set-set .post {
	padding: 30px 30px !important;
	background: rgba(0, 0, 0, 0.2) !important;
}
.createCompetitionArticle .cz-set-set .post div {
	width: 100% !important;
	max-width: 100% !important;
	margin: 0 !important;
}
.createCompetitionArticle .cz-set-set .post div p {
	font-size: 17px !important;
	font-weight: 300 !important;
}
body .createCompetitionArticle .cz-set-set .post {
	padding: 30px 30px !important;
	background-color: rgba(0, 0, 0, 0.2) !important;
}
.createCompetitionArticle .column .post-date .post-author p span img {
	width: 16px;
	margin-right: 3px;
}
.createCompetitionArticle .column .post-date .post-author p a.follow_btn {
	border: 2px solid #C83E93 !important;
	color: #C83E93 !important;
	border-radius: 5px;
	padding: 10px 10px;
	text-decoration: none;
	font-size: 17px;
	font-weight: 400;
	text-transform: capitalize;
	display: flex;
	align-items: center;
	max-width: 103px;
}
.createCompetitionArticle .column .post-date .post-author p a.follow_btn span {
	color: #C83E93 !important;
	border: 1px solid #C83E93;
	border-radius: 100%;
	width: 16px !important;
	height: 16px;
	line-height: 12px;
	text-align: center;
	margin-right: 5px;
}
.createCompetitionArticle .column .post-date .post-author p a.follow_btn img {
	width: 20px;
	margin-right: 6px;
}

.ui.grid.bountyInfo .column {
	border-radius: 15px;
	padding: 30px;
	display: block;
	margin-bottom: 25px;
	margin: auto;
	ul {
		text-align: center;
		li {
			display: inline-block;
			margin-bottom: 3px;
			margin-right: 20px;
			color: #fff;
			span {
				color: var(--pink);
			}
		}
	}
}

.ui.grid.tabBar .column {
	border-radius: 15px 15px 0 0;
	padding: 30px 30px;
	display: block;
	background: #1b1b1b;
}
.ui.grid.tabBar .column .select_box {
	color: #919191;
	font-size: 14px;
	font-weight: normal;
}
.ui.grid.tabBar .column .select_box .text {
	font-weight: normal;
	color: #bbbbbb;
}
.ui.grid.tabBar .column .select_box img {
	margin-top: -5px;margin-right: 5px;
}
.ui.grid.tabBar .column p {
	color: #919191;
	font-size: 14px;
	line-height: 21px;
}

.ui.grid.tabBar .column ul {
	padding: 0;margin: 0;
	list-style: none;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
.ui.grid.tabBar .column ul li {
	display: inline-block;margin-bottom: 3px;
	margin-right: 20px;
}
.ui.grid.tabBar .column ul li a {
	color: #9BB0CF;
	text-decoration: none;
	font-weight: 500;
	font-size: 14px;
}
.ui.grid.tabBar .column ul li {
	color: #fff;
}
.ui.grid.tabBar .column ul .item_active {
	color: #C83E93;
	border-bottom: 2px solid;
}
.ui.grid.tabBar .column ul li .tab_box {
	min-width: 205px;
}

.content-wrapper.cz-bountyPageWrap .post-preview-info {
	padding: 0 !important;
}
.content-wrapper.cz-bountyPageWrap .post-preview-info .pay_btn {
	position: absolute;
	background: #C83E93;
	padding: 10px 10px;
	margin: auto;opacity: 0;transition: all 0.2s ease-in-out;
	left: 0;
	right: 0;
	max-width: 90px;
	border-radius: 8px;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	color: #1b1b1b;
	top: 0;
	bottom: 0;
	height: 40px;
	line-height: 24px;
	font-size: 15px;
	font-weight: 400;
}

.content-wrapper.cz-bountyPageWrap .post-preview-info.img_box .post-preview-avatar {
	width: 100%;	
	transition: all 0.2s ease-in-out;
	height: 100%;
	max-width: 100% !important;
	border-radius: 0;
	opacity: 0.5 !important;
}
.content-wrapper.cz-bountyPageWrap .post-preview-info .column.dark-text .icon_img {
	width: 15px;
}

.ui.grid.row.post-bounties {
	flex-direction: row-reverse;
}

.content-wrapper.cz-bountyPageWrap .post-preview-info .position_box {
	margin: 0;position: absolute;
}
.content-wrapper.cz-bountyPageWrap .post-preview-info .icon_thumb {
	//margin-left: 55px !important;
}
.content-wrapper.cz-bountyPageWrap .post-preview-info .position_box p {
	color: #C83E93;
	background: #1B1B1B;
	display: inline-block;
	width: 55px;
	height: 55px;
	line-height: 55px;
	font-size: 19px;
	text-align: left;
	padding-left: 10px;
}
// .content-wrapper.cz-bountyPageWrap .post-preview-info .bottom.tag_box {
// 	text-align: right;
// 	float: right;
// 	width: 100%;
// }
.content-wrapper.cz-bountyPageWrap .post-preview-info .bottom.tag_box {
	padding: 0;
	margin-top: -15px;
}
.content-wrapper.cz-bountyPageWrap .post-preview-info .bottom.tag_box .ui.label {
	color: #C83E93;
	border-radius: 30px;
	background: #1b1b1b;
	font-size: 14px;
	width: auto;
	margin-left: auto !important;
}

.content-wrapper.cz-bountyPageWrap .post-bounties {
	margin-bottom: 30px;
	overflow: hidden;
	border-radius: 0;
}
.content-wrapper.cz-bountyPageWrap .all_post-bounties {
	background: rgba(0, 0, 0, 0.2) !important;
	padding-top: 15px;
	padding-bottom: 15px;
	margin-bottom: 80px !important;
	border-radius: 0 0 10px 10px;
}
.ui.grid.tabBar .column ul {
	float: left;
}
.ui.grid.tabBar .column a {
	float: right;
}
.Bounty_competitionsArticle .post-preview-info.img_box a:hover .post-preview-avatar {
	opacity: 1 !important;
	transition: all 0.2s ease-in-out;
}
.Bounty_competitionsArticle .post-preview-info.img_box a:hover .pay_btn {
	opacity: 1 !important;
	transition: all 0.2s ease-in-out;
}
.content-wrapper.cz-bountyPageWrap.Bounty_competitionsArticle .social_wrap.btnWrap svg {
	fill: #fff;
	width: 18px;
	margin-right: 8px;
}
.content-wrapper.cz-bountyPageWrap.Bounty_competitionsArticle .social_wrap.btnWrap span {
	color: #fff;
	text-decoration: none;
}
.content-wrapper.cz-bountyPageWrap.Bounty_competitionsArticle .social_wrap.btnWrap .sc-htpNat {
	display: flex;
	align-items: center;
	justify-content: center;
}
.content-wrapper.cz-bountyPageWrap.Bounty_competitionsArticle .social_wrap .social-buttons-container {
	width: 100%;
	flex-wrap: wrap;
	height: 100%;
	text-align: left;
	justify-content: flex-start;
}
.content-wrapper.cz-bountyPageWrap.Bounty_competitionsArticle .social_wrap .social-buttons-container a {
	border-radius: 10px;
	min-width: 146px;
	display: inline-block;
	padding: 0px 10px;
	line-height: 40px;
	height: 40px;
	text-decoration: none;
	margin-right: 10px;
	margin-bottom: 10px;
}
.content-wrapper.cz-bountyPageWrap.Bounty_competitionsArticle .social_wrap .social-buttons-container a div {
	background: transparent;
	padding: 0;
}
.content-wrapper.cz-bountyPageWrap.Bounty_competitionsArticle .social_wrap .social-buttons-container a[title="Share on Facebook"] {
	background: #38467C;
}
.content-wrapper.cz-bountyPageWrap.Bounty_competitionsArticle .social_wrap .social-buttons-container a[title="Share on Telegram"] {
	background: #5E95DC;
}
.content-wrapper.cz-bountyPageWrap.Bounty_competitionsArticle .social_wrap .social-buttons-container a[title="Share on Twitter"] {
	background: #6098DF;
}
.content-wrapper.cz-bountyPageWrap.Bounty_competitionsArticle .social_wrap .social-buttons-container a[title="Share on LinkedIn"] {
	background: #32619C;
}
.content-wrapper.cz-bountyPageWrap.Bounty_competitionsArticle .social_wrap .social-buttons-container a[title="Share on WhatsApp"] {
	background: #52C25B;
}
.content-wrapper.cz-bountyPageWrap.Bounty_competitionsArticle .social_wrap .social-buttons-container a[title="Share on Reddit"] {
	background: #5D83BA;
}

@media(max-width:1199px){
	.createCompetitionArticle .cz-post-header-top .post-featured-image {
    height: auto;
	}
	.content-wrapper.cz-bountyPageWrap {
		padding-left: 45px;
	}
	.cz-bountyPage {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
	}
	.cz-bountyPage.single {
		// margin-top: 140px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}
}

@media(max-width:1024px){

	.cz-bountyPage {
    width:100%;
    margin-left: auto;
    margin-right: auto;
	}
	.cz-bountyPage.single {
		// margin-top: 140px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}
	.content-wrapper.cz-bountyPageWrap {
		width: calc(100% - 0px) !important;
		margin: 0;
		padding-left: 15px;
	}
	.createCompetitionArticle .column.post-header .post-header__visual span {
    display: none;}

		.createCompetitionArticle .column.post-header {
			padding: 15px 15px 0 15px;
	}
	.createCompetitionArticle .column.post-header h3 {
    font-size: 28px;
	}
  }

	.ui.container.feed.cz-con-new.pre_loader_grid {
    padding-top: 0 !important;
}

@media(max-width:991px){

	.createCompetitionArticle .column .post-date {
    width: 100%;
    padding: 20px 0px;
    display: block;
    align-items: center;
}

	.bountySubmitArticle {
    // margin-top: 10px;
	}

  .bountyHeading {
    display: block;
		margin-bottom: 30px;
  }
  .cz-bountyPage {
    margin-top: 0px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0 0px;
  }
  .cz-bountyPage .ui.container.feed.cz-con-new {
    padding: 20px 0px;
    background: transparent;
    margin: 0;
    max-width: 100% !important;
    min-height: 530px;
    margin: 0 !important;
  }
  .cz-bountyPage .ui.container.feed.cz-con-new .ui.grid .row .sixteen.wide.column .ui.container {
    max-width: 100% !important;
    padding: 0;
    margin: 0 !important;
  }
}


@media(max-width:767px){

.bounty_card_footer{
	position: inherit;
}

.bounty_card_footer_right {
	position: initial;
	float: right;
}

.bounty_card_footer_right {
	.bounty_reward{
		position: inherit;
	}
}

.bounty_card_footer {
	position: inherit;
	float: left;
	width: 40%;

	p.bounty_reward {
    position: inherit;
    width: auto;
	}
}

.bounty_reward {
	position: absolute;
	bottom: 220px;
	margin: auto !important;
	font-size: 14px;
	color: #000;
	font-weight: bolder;
	background: #01CDFD;
	padding: 1px 9px;
	border-radius: 3px;
}
.bounty_status {
	color: #BBBBBB;
	position: absolute;
	bottom: 220px;
	right: 25px;
	margin: auto !important;
	font-size: 14px;
	font-weight: bolder;
	padding: 1px 9px;
}
	
	.cz-bountyPage {
		// margin-top: 140px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}
	.tabBar.article_tab .column ul li {
		margin: 0 20px;
	}
	.tabBar.article_tab .column ul li:first-child {
		display: inline-block !important;
		width: auto !important;
	}
	.tabBar.article_tab .column li {
		float: none !important;
		display: block;
		text-align: center;
		margin-top: 10px;
	}
	.tabBar.article_tab .column ul {
		float: none !important;
	}
	.bountySubmitArticle {
    padding: 0px 20px !important;
    line-height: 42px !important;
	}
	body .createCompetitionArticle .cz-set-set .post {
    padding: 20px 15px !important;
	}
  .competitionArticle .column {
    width: 100% !important;
  }
  .createCompetitionArticle .column .post-header span {
      display: none;
  }
  .createCompetitionArticle .column .post-date .post-author p .time-txt {
    color: #9BB0CF !important;
    font-size: 12px;
    font-weight: 300;
    margin: 0;
		line-height: 20px;
    display: block;
    width: 100%;
    text-align: left;
}
// .createCompetitionArticle .column .post-date .post-author p span {
//   display: none;
// }
.createCompetitionArticle .column .post-date .post-author .text-author {
	width: 100%;
}
.createCompetitionArticle .img-editor {
  margin: 0 !important;
}
.approveHeading h1 span {
	font-weight: 400;
	display: block;
}
.createCompetitionArticle .column .post-date .post-author p span.dot {
	display: none;
}
.createCompetitionArticle .column .post-date .post-author p span {
	width: auto !important;
	display: inline-block !important;
}
.createCompetitionArticle .column .post-date .post-author p .user_name {
	float: none;
	width: 100%;
}

.ui.grid.tabBar .column {
	padding: 0 0 20px 0px;
}
.ui.grid.tabBar .column ul li:first-child {
	display: inline-block;
	margin-bottom: 3px;
	margin-right: 0;
	width: 100%;
	text-align: center;
}
.ui.grid.tabBar .column ul li .tab_box {
	min-width: 100%;
	width: 100%;
	border-bottom: 1px solid #2d2d2d;
	padding: 15px 10px;
}
.ui.grid.tabBar .column ul {
	justify-content: center;
}
.ui.grid.tabBar .column ul li {
	padding: 0 5px;
}
.content-wrapper.cz-bountyPageWrap.Bounty_competitionsArticle .social_wrap .social-buttons-container a {
	min-width: auto;
	line-height: 30px;
	height: 30px;
}
.content-wrapper.cz-bountyPageWrap.Bounty_competitionsArticle .social_wrap .column {
	padding: 0;
}
.createCompetitionArticle .column .post-date .post-author p {
	display: block;
}
.createCompetitionArticle .column .post-date .post-author p a.follow_btn {
	padding: 7px 10px;margin-left: -3px;
	font-size: 13px;
	max-width: 87px;
	margin-top: 10px;
}
.createCompetitionArticle .column .post-date .post-author p span.dot {
	margin: 0 5px;
}
.createCompetitionArticle .column .post-date .post-author p .times-txt {
	font-size: 12px;
	font-weight: 300;
	margin: 0 !important;
}
.content-wrapper.cz-bountyPageWrap .post-preview-info .position_box {
	margin-left: 0px !important;
}
.content-wrapper.cz-bountyPageWrap .post-preview-info .icon_thumb {
	margin-left: 40px !important;margin-right: 5px;
}
}
.content-wrapper.cz-bountyPageWrap .post-preview-info .dark-text.time_box {
	position: absolute;
	top: 0;
	right: 20px;
}
.bountiesCard_view .grayscale {
	filter: grayscale(1);
}
.bountiesCard_view .swiper-wrapper .swiper-slide .card {
	flex-direction: column-reverse;
}
.bountiesCard_view .swiper-wrapper .swiper-slide .card .image {
	border-radius: 0 0 10px 10px !important;
}
.bountiesCard_view .swiper-wrapper .swiper-slide .card .description {
	margin: 0 !important;
	color: #BBBBBB !important;
	font-size: 14px !important;
}






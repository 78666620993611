@import url('https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.2.11/semantic.min.css');
@import url('https://rsms.me/inter/inter-ui.css');

// * {
//   font-family: 'Inter UI', sans-serif;
// }
html {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

body {
  -webkit-overflow-scrolling: touch;
  font-size: 16px;
  width: 100%;
  height: auto;
  font-weight: 400;
  background-color: var(--page-back);
  color: var(--text-color);
  font-family: 'Lexend', 'Inter UI', sans-serif;
  overflow: hidden !important;
}

*::-webkit-scrollbar {
  display: none;
  width: 0;
}

*::-webkit-scrollbar-button {
  display: none;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
  margin-top: 80px !important;
}

*::-webkit-scrollbar-track-piece {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: transparent;
  border: 1px solid var(--pink);
  border-radius: 53px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: var(--pink);
  border: 0px solid transparent;
  box-shadow: none;
}

#root {
  width: 100%;
  overflow: hidden !important;
}

.ui.header {
  color: var(--green);
}

.ui.button {
  width: auto;
  height: auto;
}
.ui.button {
  width: 100%;
  border: solid 2px #C83E93;
  background:var(--back-color);
  border: 1px solid transparent;
  &:hover {
    & .icon {
      color: var(--header-back);
    }
  }
  &:active {
    transform: scale(0.9);
  }
}

.ui.segment.pushable {
  background-color: var(--back-color) !important;
}

.trybe_runner{
  margin-top: 3rem;
  border: solid 5px var(--text-color)
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.ui.statistics {
  .statistic {
    margin-right: 0;
  }

  .value {
    color: var(--green) !important;
    font-family: 'Lexend', "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    font-weight: 100 !important;
  }

  .label {
    color: var(--green) !important;
  }
}

.ui.statistic {
  .label {
    color: var(--title-dark);
    text-align: left;
  }

  .value {
    color: var(--green);
    text-align: left;
  }
}

.app {
  color: var(--text-color);
  min-height: 100%;
  padding: 0;
  margin: 0;
  width: 100%;

  a {
    &:hover {
      color: var(--green);
    }
  }

  .tr-container {
    .ui.grid>.column:not(.row) {
      padding: 0 7px;
    }
  }

  .tr-block-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
    margin-bottom: 24px;
  }

  .tr-rounded-box {
    border-radius: 8px;
    background-color: var(--back-box-color);

    &__text {
      font-size: 10px;
      line-height: 1.4;
      text-transform: uppercase;
    }

    &__icon {
      width: 36px;
      height: 36px;
      border-radius: 13px;
      margin: 0 auto 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--green {
      background-color: rgba(77, 212, 182, 0.2);
    }

    &--blue {
      background-color: rgba(41, 205, 251, 0.2);
    }

    &--pink {
      background-color: rgba(238, 51, 116, 0.2);
    }

    &--yellow {
      background-color: rgba(204, 238, 63, 0.2);
    }

    &--orange {
      background-color: rgba(238, 154, 51, 0.2);
    }
  }

  .tr-expl-wrapper {
    max-width: 984px;
  }

  .tr-expl-item {
    margin-bottom: 24px;
    padding-left: 66px;
    position: relative;
    padding-right: 50px;

    a {
      text-transform: uppercase;
      color: var(--green);
    }

    &__count {
      left: 0;
      position: absolute;
      color: #4dd4b6;
      font-size: 20px;
      display: flex;
      height: 42px;
      width: 42px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background-color: rgba(77, 212, 182, 0.1);
    }
  }
}

.app-body {
  //height: 100vh - $header-height;
  height: 100% !important;
  position: static;
  top: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  max-width: 100vw;
  //margin: auto;
  color: var(--text-color);
  //padding-bottom: 80px;
  .ui.container{
    max-width: 100vw!important;
  }
}

.downloadPage {
  max-width: 1050px;
  margin: 0 auto 50px;
  padding: 60px 20px 0;
}

.downloadPage * {
  color: white !important;
}

.downloadPageContent h6 {
  font-size: 46px;
  margin-bottom: 20px;
}

.downloadPageContent p {
  opacity: .8;
  font-weight: 300;
  font-size: 18px !important;
  margin: 25px 0;
}

.downloadPageContent {
  margin-bottom: 80px;
  margin-top: 80px;
}

.downloadPageVideos {
  display: flex;
  justify-content: space-between;
}

.downloadPageVideosLeft {
  width: calc(50% - 15px);
}

.downloadPageVideosLeft iframe {
  width: 100%;
  border-radius: 10px;
}

.downloadPageVideosLeft h6 {
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 20px;
}

.downloadButtons button {
  padding: 0 !important;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  margin-right: 30px;
  font-size: 20px;
  a{text-decoration: none;}
}

.downloadButtons button img {
  margin: 0 10px;
  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
}
.downloadButtons button:hover img:last-child{transform: rotate(-90deg);}
.downloadButtons {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.downloadBottom {
  padding-top: 100px;
  padding-bottom: 100px;
}

.downloadBottom h6 {
  font-size: 46px;
}

.downloadBottom  p {
  line-height: inherit !important;
  font-size: 18px !important;
  font-weight: 300;
  opacity: .8;
  padding-top: 10px !important;
}

.downloadBottom h5 {
  font-size: 24px;
  font-weight: 400;
  padding-top: 40px;
  padding-bottom: 10px;
}

.downloadBottom ul {
  list-style: none;
  margin: 0 0 80px;
  padding: 0;
  font-weight: 300;
  opacity: .8;
  line-height: 30px;
}

.downloadBottom h4 {
  font-size: 24px;
  font-weight: 400;
}

.downloadBottom h4 img {
  max-width: 100%;
  display: block;
  margin-top: 15px;
  margin-bottom: 30px;
}

.downloadBottom h4 + p {
  position: relative;
  padding-left: 35px;
  padding-top: 0;
}

.downloadBottom h4 + p img {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -10px;
}


.categories{
  .search-input {
    .ui.action.input {
      width: 100%;
    }
    & input {
      background: var(--background-light); // border: 1px solid gray;
      transition: border .3s ease;
      border-radius: 6px;
      &:focus {
        background: var(--page-back); // border: 1px solid white;
        border-radius: 6px;
        color: var(--text-color);
      }
    }
    & .icon {
      color: var(--text-secondary);
    }
    .ui.button {
      width: 100%;
      border: solid 2px #C83E93;
      background:var(--back-color);
      border: 1px solid transparent;
      &:hover {
        & .icon {
          color: var(--header-back);
        }
      }
    }
  }
  &__mb {
    margin-bottom: 400px !important;
  }
  &__mt {
    margin-top: 100px !important;
  }
  img {
    border-radius: 4px;
  }
  h3 {
    color: var(--text-color);
  }
  h5 {
    color: var(--title-dark);
  }

  .categories_bg {
    background: var(--background-light) !important;
    margin-top: 40px !important;
    padding: 20px;
    text-align: center;

    &:hover {
      color: var(--text-color);
      background-color: var(--green);
      border: 1px solid var(--green);
    }

    &__active {
      background-color: var(--green) !important;
    }
  }
  .ui.horizontal.segments {
    margin-bottom: 1.9rem;
  }
}

.app-header {
  background-color: var(--message-summary-header);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--text-color);
}

.ui.inverted.menu {
  background-color: var(--message-summary-header);
}

.ui.segment {
  background-color: var(--back-color);
  border-radius: 0 !important
}

.ui.page.modals.dimmer.transition.visible.active {
  background-color: rgba(0,0,0,.5);
}
// .ui.mini.modal{position: initial;
//   margin: 0;
//   width: 100%;
//   background: transparent !important;}
img.emoji {
  width: 20px;
}

@media only screen and (max-width: 1024px) {
  .app {
    .tr-expl-item {
      padding: 0 !important;
    }
  }
  
  .hide-tablet {
    display: none !important;
  }

  .post-container {
    max-width: 850px;
    margin: auto !important;
}
}
@media only screen and (max-width: 767px) {
  .downloadButtons button{margin-right: 0; font-size: 15px;}
  .downloadBottom h6, .downloadPageContent h6 {
    font-size: 26px;
}
.downloadPageVideos {
  flex-direction: column;
}

.downloadPageVideosLeft {
  width: 100%;
}

.downloadPageVideosLeft:first-child {
  margin-bottom: 50px;
}
}
@media only screen and (min-width: 1025px) {
  .hide-desctop {
    display: none !important;
  }
  .post-container {
    max-width: 1500px;
    margin: auto !important;
  }
}


/*
canvas {
  width: 100%; */
.leaderboard.cz-leaderboard{ margin: 0 auto;}
.ui.fluid.container.cz-leaderboard-table .ui.grid .three.column.row .column .leaderboard_header {
  background: #1b1b1b;
  padding:0;
  position: relative;
}
.ui.fluid.container.cz-leaderboard-table .ui.grid .three.column.row .column .leaderboard_body a.ui.celled.list .item .content {
  margin-top: 5px;
  max-width: calc(100% - 50px);
}
// .ui.fluid.container.cz-leaderboard-table {
//   margin: 0 !important;
//   padding: 0;
//   max-width: 100% !important;
// }

.ui.fluid.container.cz-leaderboard-table h4 {
  color: white;
  font-weight: normal;
}
.ui.fluid.container.cz-leaderboard-table .ui.grid .three.column.row .column .leaderboard_header h2.ui.header {
  padding: 0 10px;
  height: 100%;
  display: flex;
  align-items: center;;
}

.ui.fluid.container.cz-leaderboard-table .ui.grid .three.column.row .column .leaderboard_header h2.ui.header i {
  padding: 0;
  background: #1313139e;
  width: 70px;
  height: 70px;
  position: absolute;
  top: 50%;
  transform: translate(0px, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 5px;
  border-radius: 100px;
}

.ui.fluid.container.cz-leaderboard-table .ui.grid .three.column.row .column .leaderboard_header h2.ui.header .content {
  color: #01CDFD;
  text-align: left;
  padding-left: 82px;
  font-size: 15px;
  font-weight: 700;
}

.ui.fluid.container.cz-leaderboard-table .ui.grid .three.column.row .column .leaderboard_header h2.ui.header .content .sub.header {
  color: white !important;
  padding-top: 3px;
  font-size: 17px;
  font-weight: 300;;
}
.ui.fluid.container.cz-leaderboard-table .ui.grid .three.column.row .column .leaderboard_body {
  background: #13131382;
  border: none;
  padding: 8px 0 12px;
}

.ui.fluid.container.cz-leaderboard-table .ui.grid .three.column.row .column .leaderboard_body a.ui.celled.list {
  display: block;
  padding: 0;
  margin-bottom: 8px;
}

.ui.fluid.container.cz-leaderboard-table .ui.grid .three.column.row .column .leaderboard_body a.ui.celled.list .item {
  background: #13131382;
  margin: 0 8px !important;
  position: relative;
  transform: scale(1);
}

.ui.fluid.container.cz-leaderboard-table .ui.grid .three.column.row .column .leaderboard_body a.ui.celled.list .item img {
  border: none;
}

.ui.fluid.container.cz-leaderboard-table .ui.grid .three.column.row .column .leaderboard_body a.ui.celled.list .item .content .header {
  color: white;
  font-weight: normal;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;

}
.leaderboard_header.cz-leaderboard_header + .leaderboard_body a .content {
  width: 100% !important;
}
.ui.fluid.container.cz-leaderboard-table .ui.grid .three.column.row .column .leaderboard_body a.ui.celled.list .item .content .header + .description {
  color: #9BB0CF;
  font-size: 10px;
  padding-top:5px;
  font-weight: normal;
}
.ui.fluid.container.cz-leaderboard-table .ui.grid .three.column.row .column .leaderboard_body a.ui.celled.list .item .content {
  margin-top: 5px;
  width:calc(100% - 95px);
}
.ui.list .list > .item > img.image + .content, .ui.list > .item > img.image + .content + .right.floated.content {
  max-width: 40px !important;
}
.ui.list .list>.item>img.image+.content, .ui.list>.item>img.image+.content + .right.floated.content {
  position: absolute;
  top: 50%;
  right: 0;
  margin: 0;
  transform: translate(-10px, -50%);
  background: #2d2d2d;
  width: 35px;
  text-align: center;
  padding: 4px;
  border-radius: 6px;
  margin-top: 0 !important;
}

.ui.list .list>.item>img.image+.content, .ui.list>.item>img.image+.content + .right.floated.content span.percent_stat {
  color: #01CDFD;
  font-size: 12px;
}
.column.cz-percentage .right.floated.content span.percent_stat {
  color: #C83E93 !important;
}

.column.cz-percentage .right.floated.content {
  width: 40px !important;
}
.leaderboard {
  .ui.container {
    @media #{$for-phone-only} {
      max-width: 95% !important;
    }
  }
  .leaderboard_body {
    width: 100% !important;
    padding-top: 20px;
    padding-bottom: 100px;
    background: #fff;
    border: solid 2px var(--green);
    border-top: none;
    border-radius:  0 0 12px 12px;
    &__picture {
      border-radius: 50%;
      border: solid 2px #0c5b4c;
    }
    .percent_stat {
      color: #000;
      font-weight: bolder;
    }
    .number {
      color: #000;
    }
  }
  .list {
    margin: 0;
    padding: 10px;
    .item {
      margin: 0 10px !important;
      background-color: #fff;
      border: none !important;
      border-radius: 12px;
      padding: 10px !important;
      box-shadow: 0 5px 7px -1px rgba(51, 51, 51, 0.23);
      transition: transform .25s cubic-bezier(.7,.98,.86,.98), box-shadow .25s cubic-bezier(.7,.98,.86,.98);
      &:hover {
        cursor: pointer;
        transform: scale(1.01);
        box-shadow: 0 9px 47px 11px rgba(51, 51, 51, 0.18);
      }
    }
    .image {
      width: 50px;
      height: 50px;
    }
    .content {
      color: #999;
      font-size: 16px;
      margin-top: 8px;
      .header {
        font-size: 18px;
      }
    }
  }
  .leaderboard_header{
    --start: 15%;
    width: 100% !important;
    height: 100px;
    background-image: repeating-radial-gradient(circle at var(--start), transparent 0%, transparent 10%, rgba(26, 26, 36, 0.04) 10%, rgba(13, 95, 80, 0.14) 17%), linear-gradient(to right, #4dd4b6, #095a4b);
    font-size: 18px;
    color: #e1e1e1;
    padding: 12px 13px 18px;
    border-radius: 10px 10px 0 0;
    color: #fff;
    .header {
      width: 100%;
      margin: auto !important;
      padding: 10px;
      display: table;
      .icon {
        margin: 10px;
        color: #fff;
      }
      .content {
        text-align: right;
        color: #fff;
      }
      .sub {
        color: yellow !important;
      }
    }
  }
  .segment  {
    border: none !important;
    box-shadow: none !important;
}
  .ui.menu {
    margin-top: 50px;
    border-bottom: solid 1px var(--green) !important;
    a {
      font-size: 18px;
      color: var(--green);
      font-weight: bolder !important;
      &:hover {
        color: var(--green) !important;
      }
    }
    .active {
      font-size: 18px;
      color: var(--text-color) !important;
      border-color: var(--text-color) !important;
    }
}
  .top_rank{
    margin-left: auto;
    margin-right: auto;
    img {
      margin: auto;
      background-color: #fff !important;
      border: 7px solid #fff !important;
      box-shadow: 0 0 0 8px var(--green) !important;
      width: 250px !important;
    }
    &__number {
      background-color: var(--green) !important;
      color: #fff !important;
      margin: auto !important;
      position: absolute;
      top: 230px;
      right: 0;
      left: 0;
      width: 50px;
    }
    h3 {
      margin-top: 40px;
      text-align: center;
      .sub {
        color: var(--text-color) !important;
      }
    }
  }

  .all_rank{
    img {
      margin: auto;
      border: 5px solid #fff !important;
      width: 150px !important;
    }
    &__number {
      background-color: var(--green) !important;
      color: #fff !important;
      margin: auto !important;
      position: absolute;
      top: 230px;
      right: 0;
      left: 0;
      width: 50px;
    }
    h3 {
      text-align: center;
      .sub {
        color: var(--text-color) !important;
      }
    }
  }

  .top_engagers{
    margin-left: auto;
    margin-right: auto;
    .card {
      img {
      margin-left: auto;
      margin-right: auto;
      width: 120px;
      border-radius: 100px !important;
      background-color: #fff !important;
      border: 3px solid #fff !important;
      box-shadow: 0 0 0 3px var(--green) !important;
      }

      .content {
        .header {
          text-align: center !important;
          color: var(--green) !important;
        }
        .meta {
          color: var(--title-dark);
          text-align: center !important;
          font-size: 18px !important;
          font-weight: bolder;
          margin-bottom: 10px;
        }
        .description {
          color: var(--text-color);
          text-align: center !important;
          font-size: 18px !important;
          margin: 20px !important;
        }
      }
    }
  }
}
/* NFT Page CSS start */

main.homepage.dexpage.NftPage h1.ui.header.headerContent {
	text-align: center;
}

main.homepage.dexpage.NftPage .headerBtnBox.position-relative.trade-btns-cus {
	margin-left: auto !important;
	margin-right: auto !important;
}

main.homepage.dexpage.NftPage .main_action_btn.cz-home-set-icons {
	display: flex;
}

main.homepage.dexpage .hero_banner_area .ui.grid.hero_section {
	padding-bottom: 0;
}

.dexcounter-sec {
	background-image: url(/img/angleImg.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	height: 280px;
	display: block;
	position: relative;
	margin-top: -150px;
	padding: 280px 0px 0px;
}

.trade-btns-cus button.ui.button.btn.headerBtn.loopBtn.px-3.mb-2.mb-lg-0 {
	width: auto;
}

.NftPage h1.ui.header.headerContent span {
	display: block;
	position: relative;
	margin: 0 auto 5px;
	text-transform: capitalize;
}

.NftmarketWrap .earn-img img.ui.image {
	height: 310px;
}

.decentralised-sec .yield.NftmarketWrap h2.bottomLine::before {
	background-image: none;
}

.partnerText.WorldFirstText {
	padding-right: 160px;
}

h2.bottomImg::after {
	// background-image: url(/img/LineBottom.png);
	left: 30px;
	right: 0;
	margin: auto;
}

.BondSection .partnerText.WorldFirstText {
	padding-right: 110px;
}

.marketSection .NftmarketWrap {
	padding-right: 150px;
}

.LatestBlocks .headerBtnBox.position-relative {
	margin-bottom: 40px;
}

.ExtensionSection .partnerText .HeadingWrap.wallet {
	position: relative;
}

.ExtensionSection .partnerText .HeadingWrap.wallet img.line {
	top: inherit;
	bottom: 0px;
	left: 280px;
}

.partnerText.WorldFirstText .HeadingWrap img.ui.image.line {
	width: 255px;
}

.partnerText.WorldFirstText .partnerText h5 {
	background: linear-gradient( 16deg, #FA2CAB -1.6%, #9B69FC 40.79%), #FA2CAB;
}

.decentralised-sec.BondSection .marketInner h2 {
	margin: 10px 0;
}

.WorldFirstText.partnerText h5 {
	background: linear-gradient( 16deg, #FA2CAB -1.6%, #9B69FC 25.79%), #FA2CAB;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.community_Tab h2.ui.header {
	position: relative;
	padding-bottom: 20px;
}

.NftmarketWrap div#accordionLoop {
	margin-top: 20px;
}

.ImgBtm {
	position: relative;
	display: inline-block;
}

.ImgBtm::before {
	left: 0;
	content: '';
	position: absolute;
	background-image: url(/img/LineBottom.png);
	background-size: cover;
	width: 160px;
	height: 4px;
	bottom: -5px;
	max-width: 100%;
}

.nftCounter div#shiva span {
	font-size: 32px;
}

.nftCounter .counterWrap ul li p {
	font-size: 15px;
}

.dexcounter-sec.nftCounter .counterWrap ul li::after {
	right: -32%;
}

.LatestBlocks h2.bottomImg::after {
	background-size: 150px 4px;
}


/* NFT Page CSS End */


/* jk Css End */

@media only screen and (max-width: 1500px) {
	.BondSection .partnerText.WorldFirstText {
		padding-right: 60px;
	}
}

@media only screen and (max-width: 1250px) {
	#mainNav .navbar-nav .nav-item .nav-link {
		font-size: 15px;
	}
}

@media only screen and (max-width: 1199px) {
	h1.ui.header.headerContent {
		font-size: 50px;
	}
	.hero_banner_area .ui.list .item a {
		font-size: 16px;
	}
	button.ui.button.btn.headerBtn {
		font-size: 16px;
		height: 50px;
	}
	.app-body .topIcons h3 {
		font-size: 20px;
	}
	.app-body .imgTxt h5 {
		font-size: 14px;
		line-height: 18px;
	}
	.app-body .rankHigh h5,
	.app-body .homepage .rankHigh p {
		font-size: 16px;
		line-height: 20px;
	}
	.partnerText h2,
	.HeadingWrap h1.ui.header.headerContent,
	.EarnToken .row.join-community h2 {
		font-size: 28px !important;
		line-height: 100%;
		margin-left: 0px;
		margin-top: 0;
	}
	// .communityBox {
	//   display: flex;
	//   flex-direction: column;
	//   height: 100%;
	//   justify-content: space-between;
	// }
	// .communityBox.extensionInner button.ui.button.btn.headerBtn.px-3 {
	//   width: fit-content;
	// }
	// .roadMapOuter {
	//   margin-top: 150px;
	// }
	// .hero_banner_area .ui.grid.hero_section {
	//   padding-bottom: 100px;
	// }
	.productSection h2 {
		font-size: 36px;
		max-width: 470px;
	}
	.loop-text h2 {
		font-size: 36px !important;
		line-height: 40px !important;
	}
	#mainNav .navbar-nav .nav-item .nav-link {
		font-size: 14px;
		margin-right: 5px !important;
	}
	.main_action_btn.cz-home-set-icons {
		margin-top: 0;
	}
	span.articleCount {
		padding-right: 50px;
	}
	.dexpage .homebg_section .hero_banner_area {
		min-height: 350px;
	}
	.dexcounter-sec {
		height: 250px;
		padding: 200px 0px 0px;
		margin-top: -100px;
	}
	.counterWrap.dex-counter {
		margin: 0 50px;
	}
	.decentralised-sec h2.bottomLine::before {
		right: inherit;
		left: 0;
		max-width: 250px;
		width: 100%;
		background-size: contain;
	}
	.hero_banner_area.tradeTerraSection img.ui.image.line {
		float: left;
	}
	.partnerText p,
	.tradeTerra p,
	.partnerText ul li {
		font-size: 16px !important;
	}
	.ExtensionSection .partnerText .HeadingWrap img.line {
		top: 40px;
	}
	.exchangeContent img.bottomLine {
		top: 30px;
		max-width: 220px;
	}
	.hero_banner_area.tradeTerraSection .ui.grid.hero_section {
		padding-bottom: 50px;
	}
	.ourPartnerSection {
		margin: 25px 0;
	}
	main.homepage.dexpage .hero_banner_area .ui.grid.hero_section {
		padding-bottom: 0px;
	}
	.point-des p {
		font-size: 14px;
		line-height: 20px;
	}
	.point-des h3 {
		font-size: 20px;
	}
	.earn-right-cont {
		margin-left: 30px;
		padding: 15px;
	}
	.tradeTerra {
		padding-left: 50px;
	}
	.marketSection .NftmarketWrap {
		padding-right: 20px;
	}
	.marketSection.decentralised-sec .marketInner .ecoSystem {
		padding: 0px 15px;
	}
	.BondSection .partnerText.WorldFirstText {
		padding-right: 100px;
	}
	.ExtensionSection .partnerText .HeadingWrap.wallet img.line {
		left: 0px;
	}
	.EarnToken h2.bottomImg::after {
		left: 150px;
		;
	}
	.EarnToken .row.join-community h2 {
		margin: 0px auto 25px !important;
	}
	.dexcounter-sec.nftCounter .counterWrap ul li::after {
		right: -20%;
	}
	.nftCounter div#shiva span {
		font-size: 24px;
	}
	.bg-gradient-loop.ExpandSection {
		margin-top: 80px;
	}
	main.homepage.dexpage.NftPage .hero_banner_area {
		top: 30px;
	}
}

@media only screen and (max-width: 1150px) {
	// .descInner.listing li, .dateyearLeft.rightDesc ul li {
	//   font-size: 14px !important;
	// }
	// .descRight.launch.mb-50 {
	//   margin-bottom: 90px;
	// }
	// .select::after {
	//   right: -60px;
	// }
}

@media only screen and (max-width: 1100px) {
	.select::after {
		right: -56px;
	}
	.dateyearLeft::after,
	.unselect .dateyearLeft::after {
		right: -65px;
	}
}

@media only screen and (max-width: 1080px) {
	#mainNav .navbar-nav .nav-item .nav-link span {
		padding: 5px !important;
	}
	.select::after {
		right: -12%;
	}
	.dateyearLeft::after,
	.unselect .dateyearLeft::after {
		right: -62px;
	}
	span.articleCount {
		padding-right: 20px;
	}
}

@media (max-width: 1024px) {
	.dateyearLeft.rightDesc ul li,
	.descInner.listing li {
		font-size: 12px !important;
	}
	span.articleCount {
		padding-right: 50px;
	}
	.exchangeContent img.bottomLine {
		right: inherit;
		left: 20px;
	}
	.partnerText.WorldFirstText {
		padding-right: 0;
	}
	// .counterWrap ul li::after {
	//   right: -20%;
	// }
	.cz-mobile-menu hr.sidebar-divider {
		display: none;
	}
	.cz-mobile-menu .inverted .combinedBtn {
		padding: 0 !important;
	}
	.cz-mobile-menu button.btn.headerBtn.headerBtn--login.px-3.mb-2.mb-lg-0 {
		margin-bottom: 0 !important;
	}
}

@media (max-width: 992px) {
	.integrationTab .integrationImg {
		display: none;
	}
	.appDownloadSection {
		padding-bottom: 0;
	}
	section.bg-gradient-loop.appDownload {
		padding-top: 0;
	}
	.col-sm-12.col-md-12.col-lg-6.pt-4.frameYield {
		margin-top: 3em;
	}
	.community_Tab.partners .innerboxx {
		margin-top: 4em;
	}
	section.bg-gradient-loop.community_Tab.partners {
		padding-bottom: 0;
	}
	.loopwindow {
		padding-bottom: 0;
	}
	.analitycsText {
		margin-top: 50px;
		margin-bottom: 0px;
	}
	.analitycsText span {
		display: none;
	}
	section {
		border: none;
	}
	.analitycsText .innerBox {
		padding: 30px 0px;
	}
	.masthead .col-lg-10.offset-md-1.text-center img.line {
		width: 100%;
	}
	.homebg {
		width: 100%;
	}
	.column.signupBox:last-child .mobileSignUpBox {
		margin-top: 50px !important;
	}
	.ui.grid.loopsignup {
		padding: 10px 0;
	}
	h1.ui.header.headerContent {
		font-size: 34px;
	}
	.dateyearLeft::after,
	.unselect .dateyearLeft::after {
		right: -60px;
	}
	// .communityBox {
	//   padding: 30px 20px 0 20px;
	// }
	.descInner.listing ul {
		padding-left: 0;
	}
	span.articleCount {
		padding-right: 60px;
	}
	.bg-gradient-loop.feedback_wrap .loop-text {
		max-width: 100%;
		padding-left: 15px;
		padding-right: 15px;
	}
	.decentralised-sec .marketInner .ecoSystem {
		padding: 0px 0px 0px 50px;
	}
	.hero_banner_area.tradeTerraSection {
		padding-top: 0px;
	}
}

@media (max-width: 991px) {
	.partnerSection .ui.grid>.row>[class*="four wide"].column,
	.partnerSection .ui.grid>.column.row>[class*="twelve wide"].column {
		width: 100% !important;
		text-align: center;
	}
	.loop-text {
		text-align: center;
	}
	.partnerSection .titleWrap h2 {
		text-align: center;
	}
	.counterWrap ul li p {
		font-weight: 300;
		font-size: 10px;
	}
	.dexcounter-sec div#shiva {
		font-size: 18px;
		line-height: 20px;
	}
	.nftCounter div#shiva span {
		font-size: 17px;
	}
	.nftCounter .counterWrap ul li p {
		font-size: 10px;
	}
	.counterWrap ul li::after {
		top: -5px;
	}
}

@media (max-width: 920px) {
	.dateyearLeft::after,
	.unselect .dateyearLeft::after {
		right: -55px;
	}
	.descInner.listing {
		padding: 25px;
	}
	.dateyearLeft.rightDesc {
		padding: 20px;
	}
	.dateRightWrap.qfour.mb-110 {
		margin-bottom: 90px;
	}
	span.articleCount {
		padding-right: 40px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.homepage .bg-gradient-loop.feedback_wrap .ui.grid>.row>[class*="twelve wide tablet"].column {
		width: 100%!important;
	}
}

@media (max-width: 850px) {
	.descInner.listing {
		padding: 24px;
	}
	.dateLeftWrap .dateyearLeft.rightDesc p {
		font-size: 14px !important;
	}
	.descInner.listing ul {
		padding-left: 10px;
	}
	.descInner.listing li {
		line-height: 22px;
	}
	span.articleCount {
		padding-right: 10px;
	}
	.app-body .iconsInner {
		width: 96%;
	}
	.ui.grid>[class*="two column"].row>.column.homeCounter {
		width: 100%!important;
	}
	// .counterWrap ul li::after {
	//   right: -60%;
	// }
	.bg-gradient-loop.ExpandSection {
		margin-top: 40px;
	}
}

@media (max-width: 800px) {
	.dateyearLeft::after,
	.unselect .dateyearLeft::after {
		right: -50px;
	}
}

@media (max-width: 768px) {
	.homepage .homebg_section .hero_banner_area {
		min-height: auto;
	}
	.app-body .homepage .ui.grid {
		display: flex;
		justify-content: space-around;
	}
	// .nftImg img.ui.image {
	//   left: 0px;
	// }
	.communityBox {
		padding: 20px 20px 0 20px;
	}
	.descInner.listing li {
		line-height: 18px;
	}
}

@media (max-width: 767px) {
	.hero_banner_area .ui.grid>[class*="two column"].row>.column {
		width: 100% !important;
	}
	.app-body .iconsInner {
		float: none;
		margin: 40px auto 0;
	}
	.ecoSystem.ui.grid>[class*="two column"].row>.column {
		width: 100% !important;
		margin: 15px 0;
	}
	.nftImg {
		display: flex;
		justify-content: flex-end;
	}
	.productSection .ecoSystem {
		padding-top: 20px;
	}
	.roadMapWrap.roadMapDesktop {
		display: none;
	}
	.roadMapMobile {
		display: block;
	}
	.column.nftImgRight {
		margin-bottom: 0px !important;
	}
	.titleWrap h2 {
		font-size: 30px;
	}
	.titleWrap p {
		font-size: 14px;
	}
	.blockText {
		padding: 10px;
	}
	.blockText h3 {
		font-size: 20px;
	}
	.row.join-community .blockText p {
		font-size: 14px;
		line-height: 20px;
	}
	.row.join-community h2,
	.appDownload h2,
	.bg-gradient-loop.team-section h2.ui.header {
		font-size: 36px;
	}
	h2.bottomImg::after {
		left: 0;
		right: 0;
		margin: auto;
	}
	.LatestBlocks h2.bottomImg::after {
		width: 250px;
		left: 50px;
	}
	.partnerSection {
		margin-bottom: 50px;
	}
	.dexpage .hero_banner_area .ui.list {
		margin-bottom: 0px;
	}
	.point-des h3 {
		font-size: 16px;
	}
	.earn-right-cont-close {
		margin-left: 20px;
		margin-right: 20px;
		margin-bottom: 20px;
	}
	.earn-img img.ui.image {
		height: 280px;
	}
	.decentralised-sec {
		padding: 30px 0px;
	}
	main.homepage.dexpage .hero_banner_area .ui.grid.hero_section {
		padding-top: 0px;
	}
	.ourPartnerSection .ui.grid>[class*="two column"].row>.column {
		width: 100%!important;
	}
	.ourPartnerSection .ui.grid>[class*="two column"].row>.column:last-child,
	.ourPartnerSection.ExtensionSection .ui.grid>[class*="two column"].row>.column:first-child {
		order: 1;
	}
	.ourPartnerSection .ui.grid>[class*="two column"].row>.column:first-child,
	.ourPartnerSection.ExtensionSection .ui.grid>[class*="two column"].row>.column:last-child,
	.BondSection .ui.grid>[class*="two column"].row>.column:first-child {
		order: 2;
	}
	.marketSection.exchangeSection .nftImg {
		justify-content: center;
	}
	.exchangeSection .marketInner {
		padding: 20px;
	}
	.tradeTerra {
		padding-left: 0;
	}
	.partnerText.WorldFirstText,
	.BondSection .partnerText.WorldFirstText {
		padding-right: 0;
	}
	.dexcounter-sec {
		background-size: cover;
	}
	.main_action_btn.cz-home-set-icons {
		margin-top: 20px;
	}
	// .counterWrap ul li::after {
	//   right: -50%;
	// }
	.nftCounter .counterWrap ul li p {
		font-size: 10px;
	}
	.nftCounter div#shiva span {
		font-size: 18px;
	}
	.dexcounter-sec.nftCounter .counterWrap ul li::after {
		right: -12px;
		top: -5px;
	}
	// .counterWrap ul li {
	//   display: inline-block;
	//   width: 49%;
	//   text-align: center;
	//   margin-bottom: 20px;
	// }
	// .counterWrap ul li p, .counterWrap ul {
	//   display: block;
	// }
	div#shiva {
		display: block;
		text-align: center;
		margin-bottom: 10px;
	}
	// .counterWrap ul li::after {
	//   right: 0px;
	// }
	.counterWrap ul li:nth-child(2)::after {
		display: none;
	}
}

@media screen and (max-width: 600px) {
	.homebg_section .hero_banner_area {
		background-image: none;
	}
	h2.bottomImg::after {
		width: 250px;
	}
	.ui.grid>[class*="three column"].join-community.start-learning.row>.column {
		width: 100% !important;
	}
	.blockWrap img.ui.image {
		width: 100%;
	}
	.blockWrap .blockText img.ui.image {
		width: auto;
	}
	.blockWrap {
		height: auto;
		margin: 15px 0;
	}
	.partnerSection .ui.grid>[class*="three column"].row>.column {
		width: 100% !important;
	}
	.partnerSection .ui.segment {
		margin: 10px 0 !important;
	}
	.partnerSection .ui.grid>[class*="three column"].row>.column .ui.segment:last-child {
		margin-bottom: 0px !important;
	}
	.loop-text h2,
	.bg-gradient-loop.team-section h2.ui.header {
		font-size: 28px !important;
		line-height: 40px !important;
	}
	.bg-gradient-loop.footer_wrap .tesi-design {
		padding: 40px 15px !important;
		margin: 0px;
	}
	.dexcounter-sec {
		padding: 170px 0px 0 0;
		margin-bottom: 50px;
	}
	.dexpage .homebg_section .hero_banner_area {
		padding-bottom: 0px;
	}
	.partnerText {
		padding-top: 30px;
	}
	.partnerText .HeadingWrap {
		position: relative;
	}
	.ExtensionSection .partnerText .HeadingWrap img.line {
		top: 25px;
	}
	.LatestBlocks h2.bottomImg::after {
		width: 200px;
		left: 0;
	}
	.row.join-community p {
		font-size: 14px;
	}
}

@media only screen and (max-width: 480px) {
	//   button.ui.button.joinBtn {
	//     font-size: 12px !important;
	// } 
	// .groupBtns button.ui.button.joinBtn {
	//   width: 100px !important;
	// } 
	// .groupBtns button.ui.button.joinBtn.quest {
	//   width: 120px !important;
	// }
	// .groupBtns button.ui.button.joinBtn.learnBtn {
	//   width: 80px !important;
	// }
	// .app-body main.homepage .ui.container {
	//   padding: 0px;
	// }
	// .app-body .iconProgress ul:hover, .app-body ul.activehover {
	//   padding: 10px;
	// }
	// .app-body .iconProgress ul {
	//   padding: 10px;
	// }
	// .app-body .rankHigh h5, .app-body .homepage .rankHigh p {
	//   font-size: 12px;
	// }
	// .iconTxtWrp img {
	//   width: 30px;
	// }
	// .hero_banner_area .ui.grid.hero_section {
	//   padding-bottom: 0px;
	// }
	// .productSection h2 {
	//   font-size: 24px;
	// }
	// .communityBox h2, .marketInner h2 {
	//   font-size: 22px !important;
	// }
	// .AppSection .marketInner {
	//   padding: 10px 10px 0 10px;
	// }
	// .imgTxt p, .app-body .imgTxt h5 {
	//   font-size: 12px;
	// }
	// h1.ui.header.headerContent {
	//   font-size: 26px;
	//   text-align: center;
	//   letter-spacing: normal;
	// }
	.productSection .ecoSystem {
		padding-top: 0px;
	}
	.monthContent ul {
		padding-left: 12px;
		margin-bottom: 0px;
	}
	.monthContent ul li {
		font-size: 12px !important;
		line-height: 18px !important;
	}
	.monthdate {
		padding: 10px;
	}
	.dateTxtInner {
		padding: 10px;
	}
	.monthContent p {
		font-size: 12px !important;
		text-align: left;
	}
	.dateTxt {
		width: 95%;
		display: inline-block;
	}
	.hero_banner_area button.ui.button.btn.headerBtn.px-3.mb-2.mb-lg-0 {
		width: auto !important;
	}
	h2.bottomLine::before {
		width: 230px;
		;
	}
	.marketInner {
		padding: 10px 0 10px 10px;
	}
	.communityBox {
		padding: 20px 10px 0 10px;
	}
	.groupBtns button.ui.button.joinBtn {
		margin-right: 5px;
	}
	.seedInner {
		padding-right: 10px;
	}
	// .homepage .communityBox p, .marketInner p {
	//   font-size: 14px;
	//   line-height: 18px;
	// }
	// .roadMapOuter {
	//   margin-top: 50px;
	// }
	.titleWrap {
		margin-bottom: 20px;
	}
	.community_Tab h2.ui.header,
	.appDownload h2,
	.financial_freedom h2.ui.header.integrationTabH2,
	.row.join-community h2,
	.row.exchange-section h2,
	.loop-text h2,
	.row.newsletter-section h2,
	.row.support-section h2,
	.partnerSection .titleWrap h2 {
		font-size: 24px !important;
	}
	.sidebarVisible.sidebar-wrapper .sidebar.menu .row .link.list a {
		padding: 5px 7.5px !important;
	}
	.sidebar-wrapper .ui.segment.pushable .sidebar a,
	.sidebar-wrapper .ui.segment.pushable .sidebar button {
		font-size: 14px !important;
	}
	.ourPartnerSection {
		margin: 15px 0;
	}
	.exchangeContent img.bottomLine {
		top: 22px;
	}
	.exchangeSection .marketInner {
		padding: 10px;
	}
	.decentralised-sec .marketInner .ecoSystem {
		padding: 0px 15px;
	}
	.earn-img img.ui.image {
		height: auto;
	}
	.dexcounter-sec {
		background-size: contain;
		background-position: top center;
	}
	.dexcounter-sec {
		padding: 80px 0px 0px;
		margin: -60px 0 0;
	}
	.LatestBlocks h2.bottomImg::after {
		width: 170px;
		left: -30px;
	}
	.NftmarketWrap .earn-img img.ui.image {
		height: 250px;
	}
	.decentralised-sec button.ui.button.joinBtn {
		margin-left: 60px;
	}
	.earn-right-cont-close:last-child {
		margin-bottom: 0;
	}
	.app-body .iconsInner {
		width: 90%;
	}
	.counterWrap ul li:nth-child(2)::after {
		display: none;
	}
	.counterWrap.dex-counter {
		position: relative;
		top: 20px;
		margin: 0 30px;
	}
}

@media (min-width: 576px) {
	.masthead .masthead-device-mockup .circle {
		width: 70%;
		display: block;
	}
	.masthead .masthead-device-mockup .device-wrapper {
		max-width: 12.5rem;
	}
}

@media (min-width: 768px) {
	.masthead .masthead-device-mockup .circle {
		width: 60%;
	}
	.masthead .masthead-device-mockup .device-wrapper {
		max-width: 15rem;
	}
}

@media (min-width: 992px) {
	.masthead .masthead-device-mockup .circle {
		width: 90%;
	}
	.masthead .masthead-device-mockup .device-wrapper {
		max-width: 15rem;
	}
	.masthead {
		height: 100vh;
	}
}

@media (min-width: 1200px) {
	.masthead .masthead-device-mockup .circle {
		width: 75%;
	}
	.masthead .masthead-device-mockup .device-wrapper {
		max-width: 15rem;
	}
	.ui.grid>[class*="two column"].row>.column.exchimgRight.rightSideCol {
		width: 65%!important;
	}
	.ui.grid>[class*="two column"].row>.column.leftSideCol {
		width: 35%!important;
	}
}

@media (min-width: 1400px) {
	.masthead .masthead-device-mockup .circle {
		width: 70%;
	}
	.masthead .masthead-device-mockup .device-wrapper {
		max-width: 17rem;
	}
}

.swiper-container {
	background: #130611 !important;
}

.swiper-full-mobile {
	margin: 0px auto !important;
}

.mobileApp .swiper-slide img {
	border-radius: 12px !important;
}

.team-slider .swiper-full-mobile .swiper-slide img {
	transform: scale(0.8);
	opacity: 1;
	margin-top: 315px;
}

.team-slider .swiper-full-mobile .swiper-slide.swiper-slide-prev img {
	transform: scale(1) !important;
	margin-top: 40px !important;
}

.team-slider .swiper-full-mobile .swiper-slide.swiper-slide-next img {
	transform: scale(1) !important;
	margin-top: 40px !important;
}

.team-slider .swiper-slide.swiper-slide-active img {
	transform: scale(1);
	margin-top: 0 !important;
}

.team-slider .swiper-container.swiper-full-mobile .swiper-button-prev,
.team-slider .swiper-container.swiper-full-mobile .swiper-button-next {
	top: 100px;
	left: 37%;
	width: 38px;
	height: 38px;
	padding: 0;
	background-size: contain;
	border-radius: 100px;
	background-position: center;
	background: linear-gradient( 151.28deg, rgba(227, 227, 227, 0.3) 0%, rgba(0, 0, 0, 0.1) 100%);
	border: 1px solid #423441;
}

.team-slider .swiper-container.swiper-full-mobile .swiper-button-next {
	left: auto;
	right: 37%;
}

.team-slider .swiper-container.swiper-full-mobile .swiper-button-prev:after,
.team-slider .swiper-container.swiper-full-mobile .swiper-button-next:after {
	color: white;
	font-size: 14px;
}

.tutorialLink {
	text-decoration: none;
}

@media(max-width:350px) {
	.community_Tab.partners.performers_wrap .markets_widget .portfolio-table-row:first-child td .icon-reading::after {
		right: -12px;
	}
	.community_Tab.partners.performers_wrap .markets_widget .portfolio-table-row:first-child td .icon-box::before {
		left: -12px;
	}
	.modal.display-block.modal-team .modal-main>div * {
		transform: scale(0.95) translate(-1.2%);
	}
}
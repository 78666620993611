.marketsHeader {
  display: none;
  overflow: auto hidden;
  padding:15px;
  margin-top: 10px;

  background: rgb(32, 45, 69);
  background: linear-gradient(180deg, #202d45 25%, #0a0917 58%, #19172c 100%);
  position: relative;
  z-index: 9999;
  width: 100%;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  font-feature-settings: 'cv05' on, 'cv11' on;

  color: rgba(246, 245, 255, 0.6);

  ul {
    max-width: 1440px !important;
    min-width: 1440px !important;
    list-style-type: none;
    margin: auto;
    padding: 0;
    display: flex;
     li {
      padding-left: 20px;
      span {
        color: #F6F5FF;
      }
     }
  }
}
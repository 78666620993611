$small: 450px;
$small-medium: 550px;
$medium: 900px;

.supportBG {
    //background: #0a0a19 !important;
    //background-image: url(/img/bg48095923.png) !important;
    //background-position: center !important;
    //background-size: cover !important;
}

.support_vids{
    border-radius: 0.5rem !important;
    border: solid 5px #15151c !important;
}

.support {
    // all font size
    font-family: 'Inter' !important;
    font-style: normal !important;
    margin-top: 100px;
    @media screen and (max-width: $medium) {
        margin-top: 50px;
    }
    @media screen and (max-width: $small) {
        margin-top: 20px;
    }
    .board-title {
        display: flex;
        justify-content: space-between;
        color: #F6F5FF;
        font-weight: 700;
        font-size: 32px;
        line-height: 39px;
        letter-spacing: 0.003em;
        font-feature-settings: 'cv05' on, 'cv11' on;
        margin-bottom: 32px;
        .board-title-subtitle {
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: 0.002em;
            font-feature-settings: 'cv05' on, 'cv11' on;
            color: #999CB6;
            text-decoration: none;
            div {
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;
                display: inline;
                text-align: center;
                font-feature-settings: 'cv05' on, 'cv11' on;
                color: #FFF0FA;
                margin: 0 9px;
            }
        }
    }
    .more-title {
        font-weight: 700;
        font-size: 28px;
        line-height: 34px;
        letter-spacing: 0.003em;
        font-feature-settings: 'cv05' on, 'cv11' on;
        align-items: center;
    }
    .video-details {
        .video-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            color: #F6F5FF;
        }
        p {
            font-weight: 400;
            font-size: 13px;
            line-height: 19px;
            letter-spacing: -0.01em;
            color: #999CB6;
        }
    }
    .board-tutorial-body {
        display: flex;
        justify-content: space-between;
        margin-bottom: 110px;
        .tutorial-left {
            width: 60%;
            padding-right: 43px;
            div {
                // margin-top: 28px;
            }
            h3 {
                font-weight: 600;
                font-size: 24px;
                line-height: 30px;
                letter-spacing: -0.015em;
                color: #FFF0FA;
                margin-top: 28px;
                margin-bottom: 14px;
            }
            p {
                font-weight: 400;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: 0.002em;
                color: #8C92A5;
            }
        }
        .tutorial-right {
            width: 40%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-start;
            max-height: 550px;
            overflow: scroll;
            border-radius: 10px;
            border-bottom: solid 2px #2b2a37;
            border-top: solid 2px #2b2a37;
            .video-item-row {
                display: flex;
                justify-content: space-between;
                margin-bottom: 26px;
                padding: 10px;
                &:hover {
                    background: #1A1A2E;
                    border-radius: 16px;
                    transition: ease-in-out 0.2s;
                }
                .video-screen {
                    flex: 5;
                }
                .video-details {
                    flex: 5;
                    margin-left: 20px;
                }
            }
        }
    }
    .board-tutorial-more-body {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .active {
            background: #1A1A2E;
            border-radius: 16px;
        }
        .video-item-col {
            padding: 8px;
            margin-right: 38px;
            width: 100%;
            .video-screen {
                margin-bottom: 20px;
            }
            &:hover {
                background: #1A1A2E;
                border-radius: 16px;
                transition: ease-in-out 0.2s;
            }
        }
        .video-item-col:last-child {
            margin-right: 0px;
        }
    }
    .board-foot {
        height: 64px;
        margin-top: 27px;
        .board-tutorial-more-foot {
            text-align: end;
            height: 64px;
            button {
                width: 229px;
                height: 64px;
                padding: 16px;
                background: #59D6E6;
                color: #040317;
                border-radius: 40px;
                text-decoration: none;
                font-weight: 700;
                font-size: 16px;
                line-height: 18px;
                border: 0;
            }
        }

    }
    .board-support-link {
        background: #020120;
        border-radius: 32px;
        position: relative;
        background-image: url('/img/supportLinkBG.png');
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: 102px;
        margin-bottom: 96px;
        h2 {
            font-size: 48px;
            line-height: 48px;
            padding-top: 80px;
            text-align: center;
            font-feature-settings: 'cv05' on, 'cv11' on;
            background: linear-gradient(105.62deg, #FCCFF7 -0.85%, #eb2dd5 26.92%, #8877EA 56.49%, #00f1f5 85.63%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
        }
        .link-line {
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 64px 100px 72px 100px;
            .link-item {
                transition: ease-in-out 0.2s;
                &:hover {
                    opacity: 0.8;
                    transform: scale(0.9);
                }
                &:active {
                    transform: scale(0.5);
                }
            }
        }
    }
    .video-thumbnail {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .video-background {
            background: #2B2A4F;
            border-radius: 18px;
            width: 100%;
            min-height: min-content;
            padding-top: 26%;
            padding-bottom: 26%;
        }
        img {
            position: absolute;
            background: #000;
            border-radius: 50px;
            box-shadow: 0 0 30px 15px #000000c7;
            border: solid 2px #887cec;
            &:hover {
                width: 50px;
                cursor: pointer;
            }
        }
    }
    .board-cta-support {
        text-align: center;
        width: 100%;
        height: 128px;
        padding: 16px;
        background: #59D6E6;
        color: #040317;
        border-radius: 40px;
        text-decoration: none;
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        border: 0;
        margin-top: 102px;
        margin-bottom: 96px;
        h3 {
            margin: 0 !important;
            padding: 0 !important;
        }
    }
}

#loop_player {
    width: 100%;
    height: 500px;
}


@media screen and (max-width: $medium) {
    #loop_player {
        width: 100%;
        height: 400px;
    }
    .board-tutorial-body {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
        .tutorial-left {
            width: 100% !important;
            padding-right: 0 !important;
            .video-item-row {
                display: flex;
                justify-content: space-between;
                margin-bottom: 36px;
                .video-screen {
                    flex: 5;
                }
                .video-details {
                    flex: 5;
                    margin-left: 20px;
                }
                h3 {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 24px;
                    color: #F6F5FF;
                    margin-top: 0;
                }
                p {
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 19px;
                    letter-spacing: -0.01em;
                    color: #999CB6;
                }
            }
        }
        .tutorial-right {
            width: 100% !important;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            .video-item-row {
                display: flex;
                width: 100%;
                justify-content: space-between;
                margin-bottom: 36px;
                .video-screen {
                    flex: 5;
                }
                .video-details {
                    flex: 5;
                    margin-left: 20px;
                }
            }
        }
    }
    .board-support-link {
        h2 {
            font-size: 40px !important;
            line-height: 40px !important;
            padding-top: 60px !important;
        }
        .link-line {
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 40px 30px 50px 30px !important;
            .link-item {
                width: 50px;
            }
        }
    }
}

@media screen and (max-width:$small) {
    #loop_player {
        width: 100%;
        height: 300px;
    }
    .video-item-row,
    .video-item-col {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 36px;
        .video-details {
            margin-left: 0 !important;
            margin-top: 20px;
        }
    }
    .board-support-link {
        h2 {
            font-size: 30px !important;
            line-height: 30px !important;
            padding-top: 40px !important;
        }
    }
}

@media screen and (max-width:$small-medium) {
    #loop_player {
        width: 100%;
        height: 250px;
    }
    .board-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start !important;
        a {
            margin-top: 10px;
        }
    }
    .board-tutorial-more-body {
        display: flex;
        flex-direction: column;
        .video-item-col {
            display: flex;
            width: 100%;
            justify-content: space-between;
            margin-bottom: 36px;
            padding: 0 !important;
            margin-right: 0 !important;
            .video-screen {
                flex: 5;
            }
            .video-details {
                flex: 5;
                margin-left: 20px;
            }
        }
        .active {
            padding: 20px 8px 0 8px !important;
        }
    }
    .board-tutorial-more-foot {
        button {
            width: 100% !important;
        }
    }
    .board-support-link {
        h2 {
            font-size: 35px !important;
            line-height: 35px !important;
            padding-top: 50px !important;
        }
        .link-line {
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 30px 20px 30px 20px !important;
            .link-item {
                width: 30px;
            }
        }
    }
}

.markets {
  width: 100% !important;
  margin: auto !important;
  margin-top: 50px !important;
  .mobile_view {
    display: none;
  }

  .table_header{
    &__mobile {
      display:none;
    }
  }

  .market_filters {
    display: inline-flex;
    margin-top: 50px;
    width: 100%;
    overflow: scroll;

    div {
      &:first-child {
        //border-right: solid 1px #1D1C2F;
        //padding-right: 10px;
      }
    }

    .btn {
      border: 1px solid #1D1C2F;
      border-radius: 8px;
      padding: 10px 20px;
      margin: 0 10px;
      color: #fff;
    }

    ul {
      margin-bottom: 0;
      padding-left: 1rem;
      width: max-content;
    }

    li {
      display: inline-flex;
      margin: 0 10px;
      list-style: none;
      margin: 0 10px;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #787B9C;
      a {
        text-decoration: none;
      }

      &.active {
        background: #25273A !important;
        color: #fff;
        border-radius: 8px;
        padding: 10px 20px;
      }
    }
  }

  .market_actions {
    justify-content: end !important;
    position: absolute;
    top: 17px;
    right: 20px;
    height: 70px;
    width: 450px;
    background: linear-gradient(270deg, #1a1930 87.93%, rgba(22, 21, 45, 0) 100%);
    border-radius: 16px;

    .action_button {
      display: flex;  
      border: 1px solid #1D1C2F !important;
      border-radius: 8px !important;
      padding: 10px 20px !important;
      margin: 0 10px !important;
      color: #fff !important;

      img {
        width: 20px;
        margin-left: 10px;
      }

      &:hover {
        cursor: pointer;
      }

      &__purple {
        border-color: #887CEC !important;
        color: #887CEC !important;
        &:hover {
          background: #443f74 !important;
          color: #fff !important;
        }
      }
      &__cyan {
        border-color: #59D6E6 !important;
        color: #59D6E6 !important;
        &:hover {
          background: #2e6d75 !important;
          color: #fff !important;
        }
      }
      &__swap {
        background: #59D6E6 !important;
        color: #fff !important;
        &:hover {
          background: #2e6d75 !important;
          color: #fff !important;
        }
      }
    }

    ul {
      margin-bottom: 0;
      padding-left: 1rem;
    }

    li {
      display: inline-flex;
      margin: 0 10px;
      list-style: none;
      margin: 0 10px;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #787B9C;
      a {
        text-decoration: none;
      }

      &.active {
        background: #25273A !important;
        color: #fff;
        border-radius: 8px;
        padding: 10px 20px;
      }
    }
  }

  .page_results {
    color: #999CB6 !important;
    span {
      color: #fff;
    }
  }

  .pagination {
    background: transparent !important;
    border: none;
    .active {
      background: #25273A !important;
      border-radius: 4px;
      color: #fff !important;
    }
    a {
      font-size: 15px !important;
      color: #787B9C !important;
      &:first-child, &:last-child{
        color: #fff !important;
      }
    }
  }
  h3 {
    padding-top: 15px;
    //padding-left: 20px;
    margin-bottom: 15px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 39px;

    letter-spacing: 0.003em;
    font-feature-settings: 'cv05' on, 'cv11' on;

    color: #F6F5FF !important;
    @media screen and (max-width: 769px) {
      font-size: 22px;
    }
  }
  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    color: #fff !important;

    small {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      /* identical to box height */

      letter-spacing: 0.07em;
      text-transform: uppercase;

      color: rgba(246, 245, 255, 0.6);
    }
  }
  .sub {
    //padding-left: 20px;
    text-transform: capitalize;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.002em;
    font-feature-settings: 'cv05' on, 'cv11' on;
    display: block;

    color: #999CB6 !important;
    span {
      font-weight: 500;
      color: #F6F5FF;
    }
    @media screen and (max-width: 769px) {
      font-size: 12px;
    }
  }

  .token_search {
    float: right;
    margin-top: 15px;
    input {
      background: #161529 !important;
      border-radius: 12px;
      padding: 20px;
      width: 356px;
      color: #fff !important;

      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #F6F5FF !important;
        opacity: 0.25; /* Firefox */
      }
    }

    i {
      &::before {
        font-size: 22px !important;
        color: #fff !important;
        opacity: 0.24;
        padding-left: 10px;
      }
    }
  }

  .marketPercent__down {
    background: rgba(233, 96, 117, 0.12);
    border-radius: 10px;
    padding:  3px 10px;
    font-weight: 500;
    font-size: 15px;

    color: #F58193 !important;

    @media screen and (max-width: 769px) {
      background: transparent;
      padding: 0px;
    }
  }

  .marketPercent__up {
    background: #89f5c358;
    border-radius: 10px;
    padding:  3px 10px;
    font-weight: 500;
    font-size: 15px;

    color: #89F5C2 !important;

    @media screen and (max-width: 769px) {
      background: transparent;
      padding: 0px;
    }
  }

  .ui.table td.one.wide, .ui.table th.one.wide {
    width: 1.25%;
  }
}


body .markets-table .ui.table {
  width: 100%;
  background: transparent;
  margin: 1em 0;
  border: 0;
  box-shadow: none;
  border-radius: 0.28571429rem;
  text-align: left;
  color: rgba(0,0,0,.87);

  td.two.wide.change {
    width: 5.5%;
  }
  td.two.wide.change_last {
    width: 10%;
  }
}

/* tokens css */
.markets-table.token-table-cz {
  margin-top: 0px !important;
  background: transparent;
  overflow: auto;

  .hd-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    div {
      width: 100%;
      &:last-child {
        text-align: right;
      }
    }
    .btn_trade {
      color: #8274ED;
      border: 1px solid #8274ED;
      border-radius: 8px;
      margin: 10px;
      padding: 10px 14px;
      width: 25%;
      &:hover {
        background: #8274ED;
        color: #fff;
        transition: ease-in-out 0.2s;
      }
      &__blue {
        color: #59D6E6;
        border: 1px solid #59D6E6;
        &:hover {
          background: #59D6E6;
          color: #fff;
          transition: ease-in-out 0.2s;
        }
      }
    }

    @media screen and (max-width: 769px) {
      display: block;
      div {
        width: 100%;
        &:last-child {
          text-align: center;
          margin: 30px 0 0 0;
        }
      }
      .btn_trade {
        width: 100%;
        margin: 10px 0 0 0;
      }
    }
  }

  table {
    border-collapse: collapse;
  }
  
  tbody {
    tr {
      border-color: transparent;
      border-style: solid;
      //border-width: 0 0 25px 0;
      overflow: scroll;
      min-width: 1200px;
      width: 100%;
      display: table;
      margin: 20px 0;
      @media only screen and (max-width: 767px) {
        border-color: transparent;
        border-style: solid;
        border-width: 0 0 10px 0;
      }
    }
    // Set border-radius on the top-left and bottom-left of the first table data on the table row
    td:first-child,
    th:first-child {
      border-radius: 12px 0 0 12px;
      @media only screen and (max-width: 767px) {
        border-radius: 0 0 0 0 !important;
      }
    }

    // Set border-radius on the top-right and bottom-right of the last table data on the table row
    td:last-child,
    th:last-child {
      border-radius: 0 12px 12px 0;
      @media only screen and (max-width: 767px) {
        border-radius: 0 0 0 0 !important;
      }
    }
  }
}
.markets-table.token-table-cz tbody tr {
  &:hover {
    //background: #16152D !important;
    //cursor: pointer;
  }
}
.markets-table.token-table-cz tr td {
  background: #2221374a !important;
  //border-radius: 0 !important;
  border: none;
  padding: 20px 10px !important;
  color: white;
  font-weight: normal !important;
  font-size: 14px !important;
  vertical-align: middle;

  small {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;

    letter-spacing: 0.07em;
    text-transform: uppercase;

    color: rgba(246, 245, 255, 0.6);
  }
}
.markets-table.token-table-cz tr td button {
  background: rgba(45, 45, 45, 0.35) !important;
  border: none !important;
  border-radius: 5px;
  padding: 7px 15px;
  color: var(--pink) !important;
  font-weight: 400;
  font-size: 14px;
  &:hover {
    color: #fff !important;
  }
}
.markets-table.token-table-cz table.ui.table {
  border-spacing: 0;
  margin-bottom: -20px !important;
  margin: 0;
}
.markets-table.token-table-cz tr td div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.markets-table.token-table-cz table.ui.table td {
  text-align: left;
}

.markets-table.token-table-cz table.ui.table tr td:first-child {
  text-align: left;
}
.markets-table .ui.table tbody tr td {
	position: relative;
}
.markets-table .ui.table tbody tr td b {
  background: #2d2d2d;
  width: 24px;
  height: 24px;
  display: block;
  text-align: center;
  border-radius: 100px;
  padding-top: 7px;
}

.markets-table .ui.table tbody tr td b i {
  background: #32fe9a;
  width: 10px;
  height: 10px;
  display: block;
  border-radius: 100px;
  margin: 0 auto;
}
.markets-table.token-table-cz tr td div button {
  padding: 0 !important;
  width: 24px;
  height: 24px;
}
.markets.markets-table.token-table-cz .ui.divider {
  margin: 20px 0 0;
}
.markets-table.token-table-cz table.ui.table thead tr:nth-child(1) td{
  background: transparent !important;
  //color: #fff;
  color: #787B9C !important;
  padding: 30px 10px !important;
  @media only screen and (max-width: 767px) {
    width: max-content;
    background: #787B9C !important;
    padding: 10px !important;
    font-size: 12px !important;
  }
}
  .markets-table.token-table-cz table.ui.table thead tr:nth-child(1) td:first-child {
    padding-left: 30px !important;
}
.markets-table .ui.table tbody tr td:first-child {
  padding-left: 30px !important;
}
.markets span b {
  width: 15px;
  display: inline-block;
  height: 10px;
  position: relative;
  cursor: pointer;
}



.markets-table.token-table-cz tr td button:hover {
  color: #fff !important;
  text-decoration: none;
}


.markets-table.cz-markets-table.cz-setstaking-new {
  padding: 0;
}

.markets-table.cz-markets-table.cz-setstaking-new thead tr td {
  color: white !important;
}

.markets-table.cz-markets-table.cz-setstaking-new tr.portfolio-table-row td.two.wide {color: #01ccfc;text-align: center;}

.markets-table.cz-markets-table.cz-setstaking-new tr.portfolio-table-row td.two.wide small {color: #bbb;font-size: 12px;display: block;text-align: center;margin-top: 10px;}

.markets-table.cz-markets-table.cz-setstaking-new tbody tr.portfolio-table-row td.two.wide {
  padding: 10px;
  vertical-align: baseline;
}


.markets-table.token-table-cz tr.portfolio-table-row:first-child {
  padding-top: 0px;
  padding-bottom: 0;
}

.markets-table.token-table-cz table.ui.table thead tr:nth-child(1) td:first-child {
  padding-left: 20px !important;
}




.marketUp {
  color: #32FE9A !important;
  &::before {
    content: '';
    float: left;
    display: block;
    height: 10px;
    width: 10px;
    //background-repeat: no-repeat;
    //background-image: url('data:image/svg+xml;utf8, <svg width="6" height="5" viewBox="0 0 6 5" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 2.62268e-07L6 5L4.76837e-07 5L3 2.62268e-07Z" fill="%2332FE9A"/></svg>');
  }
}
.marketDown {
  color: var(--pink) !important;
  &::before {
    content: '';
    float: left;
    display: block;
    height: 10px;
    width: 10px;
    //background-repeat: no-repeat;
    //background-image: url('data:image/svg+xml;utf8, <svg width="6" height="5" viewBox="0 0 6 5" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 5L0 5.04736e-07L6 0L3 5Z" fill="%23C83E93"/></svg>');
  }
}


@media screen and (max-width: 1399px) {

  body .markets-table.token-table-cz tr td {
    padding: 20px 20px !important;
  }
}

@media only screen and (max-width: 767px) {
  .markets {
    padding: 10px !important;
    width: 97% !important;
    .desktop_view {
      display: none !important;
    }
    .mobile_view {
      display: block !important;
    }

    .mt-20 {
      margin-top: 20px;
    }

    .card {
      width: 100%;
      background: rgba(34,33,55,.2901960784313726) !important;
      padding: 20px;
      border: none;
      box-shadow: none;
      border-radius: 10px;

      ._header {
        display: flex;
        justify-content: space-between;
      }

      .rank {
        position: absolute;
        left: 0;
        top: 0;
        background: #25273a;
        color: #fff;
        border-radius: 5px 0 5px 0;
        padding: 0 5px;
        font-size: 12px;
      }
      .fs {
        font-size: 14px;
        margin: 0;
        color: #ffffff65 !important;
        span {
          color: #fff;
        }
      }
      .desc {
        display: flex;
        justify-content: space-between;
        
        .fs-12 {
          font-size: 12px !important;
        }
      }
    }

    h3 {
      padding-left: 0px;
    }

    .sub {
      padding-left: 0px;
    }

    .market_filters ul {
      padding-left: 0;
    }


    .marketDown::before, .marketUp::before {
      content: none;
      margin-top: 7px;
    }
    .table_header{
      display: none;
      &__mobile {
        display: block;
        width: max-content;
        background: #787B9C !important;
        padding: 10px !important;
        font-size: 12px !important;
        border-radius: 0;
      }
    }
    table {
      display: block;
      overflow: scroll;
      border-radius: 5px !important;
    }
    .table_header {
      //min-width: 600px !important;
      min-width: auto !important;
      overflow: scroll;
      thead {
        display: block !important;
        width: max-content;
        overflow: scroll;
      }
    }

    
    .ui.table:not(.unstackable) tbody, .ui.table:not(.unstackable) tr, .ui.table:not(.unstackable) tr>td, .ui.table:not(.unstackable) tr>th {
      width: max-content !important;
      display: revert !important;
      padding: 0.45em 1.50em !important;
    }
  }
}
[data-test="MainBarButtonClose"] {
  display: none;
}

.ephox-summary-card {
  border: 1px solid #575459;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
  padding: 10px;
  overflow: hidden;
  margin-bottom: 1em;
}

.ephox-summary-card a {
  text-decoration: none;
  color: inherit;
}

.ephox-summary-card a:visited {
  color: inherit;
}

.ephox-summary-card-title {
  font-size: 1.2em;
  display: block;
}

.ephox-summary-card-author {
  color: #999;
  display: block;
  margin-top: 0.5em;
}

.ephox-summary-card-website {
  color: #999;
  display: block;
  margin-top: 0.5em;
}

.ephox-summary-card-thumbnail {
  max-width: 180px;
  max-height: 180px;
  margin-left: 2em;
  float: right;
}

.ephox-summary-card-description {
  margin-top: 0.5em;
  display: block;
}

.ui.comments .comment {
  //background: #1B1B1B !important;
  background: #161529 !important;
  font-weight: normal;
}

.ui.comments .comment * {
  color: white !important;
  .dangerReporthold{
    position: absolute;
    right: 0;
    span.dangerReport {
      font-size: 13px;
      color: #c63d92 !important;
      cursor: pointer;
      margin-left: 10px;

      i {
        font-size: 15px;
        color: #c63d92 !important
      }
    }
  }
}
.comment.comment-lvl-0 {
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.ui.comments .comment a.author {
  text-decoration: none;
}

.ui.comments .comment .metadata div {
  margin-top: 10px;
  color: #9BB0CF !important;
}

.ui.comments .comment .actions a {
  color: #c83e93 !important;
  font-size: 16px;
}

.ui.comments .comment .actions a svg {margin-right: 6px;}
.ui.grid.post-container.cz-single-article {
  padding-top: 133px;
  padding-bottom: 0 !important;
  margin-bottom: 30px !important;
  min-height:0px !important;
}

.ui.container.cz-back-article .cz-grid-center {
  display: flex;
  max-width: 800px !important;
  margin: 0 auto;
  .reportButton{
    width: auto;
    margin-top: 10px !important;
    background: transparent !important;
    color: #fff !important;
    cursor: pointer;
  }
}

.row.cz-post-comments .comments-container .ui.fluid.buttons button {
  border: none !important;
  background: #c83e93 !important;
  color: white !important;
}
button.ui.button.backarticle {
  background: no-repeat;
  color: #787B9C;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  padding-left: 20px;
  float: left;
  text-align: left;
}
.row.cz-post-header-top-enter .post .author-post .ui.grid .row.post-preview .eight.wide.column.post-preview-info.cz-new-card .top.aligned.row .two.wide.column {
  margin-left: 0;
  margin-right: 15px;
}

.cz-single-article-container{
  .row.cz-post-header-top-enter{
    .post{
      .author-post{
        .ui.grid{
          .row.post-preview{
            &:last-child{
              margin-bottom: 0;
            }
          }
        } 
      } 
    } 
  } 
}

.extraBioContent {
  min-height: 200px;
}

button.ui.button.backarticle:before {
    content: '\f060';
    width: 8px;
    height: 8px;
    position: absolute;
    left: -1px;
    margin-top: 1px;
    font-family: 'Font Awesome 5 Pro';
}

.cz-single-article-container {
  max-width: 820px;
  margin: 0 auto;
  //background: #1B1B1B;
  background: #19172c;
  border-radius: 15px;
  overflow: hidden;

  .row.cz-post-comments{
    .comments-container{
      .ui.comments{
        .comment{
          padding-top: 5px !important;
          padding-bottom: 5px !important;
          .content{
            margin-right: 3.5em;
          }
        }
      }
      button.replyComment,
      button.replyComment:disabled {
        color: #1B1B1B !important;
      }
    } 
  } 
}

.cz-single-article-container {
  margin-top: 10px;
}

.row.sub-header.cz-post-header-top .row .post-categories-n-tags {
  width: 100%;
  margin: 0;
  padding: 0 50px;
}

.cz-single-article-container .sub-header.cz-post-header-top .post-categories a {
    padding: 9px 15px;
    font-size: 16px;
}

.cz-single-article-container .row.cz-set-set .post {
  padding: 5px 35px 5px !important;
}

.sub-header.cz-post-header-top .post-categories .post-category:nth-child(2) a {background: #3b3b3b;}

.row.sub-header.cz-post-header-top .post-date {
  width: 100%;
  padding: 0 15px;
}

.row.sub-header.cz-post-header-top .post-date .post-author {
  position: relative;
  padding: 20px 15px;
  .ui.avatar.image,
  .ui.avatar.image img{
    width: 3em;
    height: 3em;
  } 

  .post_date_detail_colum {
    padding: 10px 6px;
    display: grid;
    .post_date_details {
      padding: 10px 6px;
      text-align: right;
    }
  }

  span.lightShade {
    color: #BBBBBB !important;
    font-weight: 500;
  }

  b{
    a{
      font-weight: 500;
    }
  }
}
.row.cz-post-header-top + div .post * {
  background: none !important;
  //color: white !important;
  //font-weight: normal !important;
  //font-size: 17px !important;
}

.row.cz-post-header-top + div .post [data-ephox-embed-iri]{
  padding-bottom: 0 !important;
}

.post-author.cz-post-mobile-only {
  position: relative;
  display: none !important;
}
.post-author.cz-post-mobile-only b:first-child {
  padding-left: 0;
  line-height: 17px;
  display: flex;
  align-items: center;
}
.sub-header.cz-post-header-top .post-date .post-author b:first-child a i {
  display: block;
  font-style: normal;
  margin: 5px 0;
}
.post-author.cz-post-mobile-only b {
  display: block;
  padding-left: 31px;
  font-weight: normal;
}
.post-author.cz-post-mobile-only button {
  position: absolute;
  right: 0;
  bottom:0;
}

.cz-single-article-container .ui.star.rating .icon.selected, 
.cz-single-article-container .ui.star.rating .icon.selected.active{
  color: #c83e93 !important;
  text-shadow: 0 -1px 0 #c83e93,-1px 0 0 #c83e93,0 1px 0 #c83e93,1px 0 0 #c83e93!important;
}

.ratingSystem{
  span.dot {
    display: inline-block;
    width: 7px;
    height: 7px;
    background: #919191;
    background-image: none;
    border-radius: 100%;
    vertical-align: middle;
    margin: 0 12px;
  }
}

.voteSection{
    padding: 1rem 3px;
  .voteButton{
    background: #c83e93 ;
    color: white ;
  }
}
.joinUsToday {
  padding: 10px 10px;
  color: #ffffff;
  border-bottom: solid 1px #31313a;

  .joinUs {
    float: left;
    width: 35%;
    background: rgba(19,19,19, 0.4);
    border-radius: 15px;
    padding: 25px 30px;
    margin-right: 5%;
    background-image: url('../staticAssets/images/joinTodayShadeLeft.png'), url('../staticAssets/images/joinTodayShadeBottom.png'), url('../staticAssets/images/joinTodayShadeUp.png'), url('../staticAssets/images/joinTodayShadeRight.png');
    background-size: auto, auto, auto, auto;
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
    background-position: center left, left bottom, center top, center right;
    img {
        width: 100%;
    }
  }
  .registerWithUs{
    float: left;
    width: 60%;
    padding: 26px 0;

    h6{
      font-family: 'Lexend', sans-serif;
      font-weight: 700;
      margin-bottom: 20px;
      font-size: 15px;
      line-height: 19px;
    }

    .joinFreeHold{
      float: left;
      width: 100%;

      .joinFree {
        float: left;
        width: 28%;
        margin-right: 5%;
        a{
          font-weight: 700;
          color: #1B1B1B;
          text-decoration: none;
          background: #c83e93;
          padding: 12px 15px;
          border-radius: 5px;
          width: 100%;
          display: inline-block;
          text-align: center;
          font-size: 14px;
          line-height: 17px;
        }
      }

      .joinFreeText{
        float: left;
        width: 67%;
        p{
          font-size: 14px;
          line-height: 21px;
          color: #BBBBBB;
        }
      }
    }
  }
}

.cz-single-article-container{
  .row.cz-post-comments{
    //background: #1B1B1B;
    background: rgba(9, 8, 23, 0.7);
    .comments-container{
      padding: 0 30px;
      .ui.attached.menu{
        background: transparent;
        margin-bottom: 0;
        a{
          font-size: 14px;
          line-height: 17px;
          font-weight: 500;
          color: #9BB0CF;
          background: unset;
          padding: 0;
          margin-right: 15px;
          i{
            color: #9BB0CF;
          }
        }

        a.active{
          position: relative;
          color: #C83E93;

          &:before{
            background: #C83E93;
            position: absolute;
            bottom: 5px;
            top: auto;
            width: 100%;
            height: 2px;
            content: ""
          }
          i{
            color: #C83E93;
          }
        }
      }

      .ui.bottom.attached.segment{
          form.ui.inverted.form{
            .field{
              textarea{
                margin-top: 10px;
                border: 0;
                background: #131313;
                font-weight: 300;
              }

              textarea::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: rgba(255,255,255,0.7);
              }
              
              textarea:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: rgba(255,255,255,0.7);
              }
              
              textarea::-ms-input-placeholder { /* Microsoft Edge */
                color: rgba(255,255,255,0.7);
              }
            } 
          } 
      }

      .ui.pagination.menu{
        background: unset;
        float: right;
        width: auto;
        .item,
        .item:hover {
          background: transparent;
          border-radius: 100%;
          color: #FFFFFF;
          width: 50px !important;
          height: 50px !important;
          min-width: auto;
          display: inline-block;
          padding: 17px 0;
          img{
            width: 10px;
          }
        }
        .item.active{
          //color: #32FE9A;
          color: var(--pink);
          text-align: center;
          font-size: 19px;
        }
      }
    }

    .cz-post-header-top-enter{
      margin: 0;
      padding: 0;
      width: 100%;
    }
  }

  .cz-new-card{
    .ui.grid{
      .floated.three{
        text-align: right;
        flex-direction: column-reverse;
      }

      .row.dark-text {
        color: #9BB0CF !important;
        font-size: 10px !important;
        line-height: 14px;
      }

      .nine.wide.column {
        padding-top: 7px !important;
      }

      .middle.aligned{
        padding-top: 0;
      }

      .bottom.aligned{
        padding-bottom: 0;

        .dark-text{
          .label{
            color: #919191;
            &:last-child{
              color: #FFFFFF;
            }
          }
        }
      }

      h4.post-preview-title{
        margin-top: 0;
      }

      p.dark-text{
        padding-top: 0;
        p{
          line-height: 21px !important;
        }
      }
    } 
  } 

  .post{
    .author-post{
      .post-preview{
        .content-img{
          height: 100% !important;
        }
      }
    }
  }
}

.commentHandle{
  h4{
    font-weight: 700;
    font-size : 19px;
    line-height: 23.75px;
  }

  .ui.comments{
    margin-top: 0;

    .comment{
      .content {
        .author{
          font-size : 17px;
          line-height: 24px;
          font-weight: 300;
        }

        .metadata{
          div{
            margin-top: 4px;
            font-size: 12px;
            line-height: 15px;
          }
        } 

        .text{
          p{
            font-size : 17px;
            line-height: 24px;
            color: #fff;
            font-weight: 300;
          }
        }
        .actions{
          a{
            text-decoration: none;

            i{
              font-size: 12px;
              margin-right: 0;
              vertical-align: middle;
              color: #c83e93 !important;
            }

            svg{
              color: #C83E93 !important;
              margin-right: 5px;
            }

            &:hover{
              opacity: 1;
            }
          }
        }
      }
    } 
  }
}

.cz-single-article-container{
  .social-buttons-container{
    a{
      margin: 0;
      font-weight: 300;
    }
  }
}

.row.sub-header.cz-post-header-top .post-date .post-author button {
  //margin: 0 15px 0 0 !important;
  margin: 10px 6px 0 auto !important;
  border: 1px solid #c83e93 !important;
  color: #c83e93 !important;
  background-color: transparent !important;
  padding: 10px;
  font-size: 17px;
  font-weight: 500;
  width: 180px;

  .plusIcon{
    margin-right: 5px;
    vertical-align: top;
    i{
      color: #c83e93 !important;
      font-weight: 500;
      font-size: 17px;
    }
  }
  span{
    color: #c83e93 !important;
  }
}
.cz-single-article-container .post-header h1 {
  width: 100%;
}
.cz-single-article-container .row.cz-post-header-top .post-featured-image {
  max-width: 100% !important;
  background: no-repeat;
  border-radius: 0;
  border-top: solid 2px #2c2b38;
  border-bottom: solid 2px #2c2b38;
}

.row.cz-post-header-top {padding: 0 !important;}

.row.cz-post-header-top .column {margin: 0 !important;}

.cz-single-article-container .column {
  padding: 0 !important;
}

.app-body{
  background: linear-gradient(112.25deg, #252D49 16.48%, #32222A 85.74%);
}

.cz-single-article-container .column .ui.grid {
  margin-left: 7px;
  margin-right: -14px;
}
.cz-single-article-container .row.sub-header.cz-post-header-top .post-header {
  padding: 25px 35px 5px !important;
  display: block;
}


.cz-single-article-container .row.cz-post-comments .comments-container .ui.fluid.buttons button{
  color: #1b1b1b !important;
  border-radius: 8px;
}

.sub-header.cz-post-header-top .post-header h1 {
  font-family: 'Lexend', 'Times New Roman', sans-serif !important;
  font-size: 48px !important;
  line-height: 55px;
  text-transform: capitalize;
  font-weight: normal;
}

.post-sub-header {
  padding: 0px 35px 25px !important;
  display: block;
}

.post-sub-header h4 {
  color: white;
  font-family: 'Lexend', 'Times New Roman', sans-serif !important;
  font-weight: 100;
  font-size: 28px;
  line-height: 32px;
}

@mixin styling($width) {
  .upload-text-wrapper {
    position: relative;
    max-width: 1050px;
    max-height: 500px;
    overflow: hidden;
    margin: auto;
    border-radius: 0;
    border: none !important;
    input {
      display: none;
    }
  }
  // your SCSS here, e.g.
  .post {
    h2 { line-height: 2.2em; margin-block-end: 0.33em !important;}
    h3 { line-height: 2em; margin-block-end: 0.23em !important;}
    h4 { line-height: 1.7em; margin-block-end: 0.13em !important;}
    h5 { line-height: 1.5em; margin-block-end: 0 !important; }
    p { margin: 0 !important; line-height: 2em !important; }
    &__visual {
      &-featured-image {
        position: relative;
        max-width: 100%;
        max-height: 700px;
        transform: scale(1.035); /* Equal to scaleX(0.7) scaleY(0.7) */
        overflow: hidden;
        margin: auto;
        //border-radius: 6px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
  
        img {
          margin: 0 auto;
          width: 100%;
          margin: auto;
          transition: .5s ease;
        }
        .icon {
          margin: auto;
          width: 100%;
          position: absolute;
          top: 0;
          bottom: 0;
          color: #1a1a24;
          opacity: 0.3;
          transition: .2s ease;
          &:hover {
            opacity: 0.2;
            cursor: pointer;
          }
        }
      }
      .post-featured-image {
        position: relative;
        width: 100%;
        min-width: 1040px;
        max-width: 100%;
        padding: 0 !important;
        max-height: 700px;
        overflow: hidden;
        margin: auto;
        border-radius: 0 !important;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
  
        img {
          margin: 0 auto;
          width: 100%;
          margin: auto;
          transition: .5s ease;
        }
        .icon {
          margin: auto;
          width: 100%;
          position: absolute;
          top: 0;
          bottom: 0;
          color: #1a1a24;
          opacity: 0.3;
          transition: .2s ease;
          &:hover {
            opacity: 0.2;
            cursor: pointer;
          }
        }
      }
    }

    div {
      max-width: 100%;
    }
    p {
      text-indent: 0 !important;
      font-size: 19px;
      font-family: 'Lexend', 'Lato', sans-serif;
      line-height: 1.5em;

      @media #{$for-tablet-portrait-down} {
        font-size: 17px;
      }
    }

    img {
      max-width: 100%;
      height: auto !important;
    }

    &-container {
      min-height: 100vh;
      padding-bottom: 20rem !important;

      .author-post {
        .grid {
          margin: auto;
        }
      }
    }

    &-author {
      width: 100%;
      margin: 0 auto;
      display: block !important;
      flex-direction: row;
      align-items: center;
      color: var(--text-color) !important;

      img {
        cursor: pointer;
      }

      a {
        margin-left: .5em;

        &:hover {
          cursor: pointer;
          color: var(--green);
        }
      }
    }

    &-date {
      width: $width;
      margin: 0 auto;
      font-size: 1.15em;
      color: var(--green);
      display: block;
      justify-content: space-between;
    }
    &-bar {
      display: flex;
    }

    &-featured-image {
      position: relative;
      max-width: 100%;
      max-height: 700px;
      overflow: hidden;
      margin: auto;
      //border-radius: 6px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;

      img {
        margin: 0 auto;
        width: 100%;
        margin: auto;
        transition: .5s ease;
      }
      .icon {
        margin: auto;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        color: #1a1a24;
        opacity: 0.3;
        transition: .2s ease;
        &:hover {
          opacity: 0.2;
          cursor: pointer;
        }
      }
    }

    &-categories-n-tags {
      display: flex;
      flex-direction: column;
      width: $width;
      margin: 0 auto;
      margin-top: 15px;

      .post-tags {
        width: 100%;
        display: flex;
        flex-direction: row;

        .post-tag {
          padding: 0 .5em;
          margin: 0 0.5em;
          border-radius: 6px;
          font-size: 1em;
        }
      }

      .post-categories {
        max-width: 100%;
        display: flex;
        flex-direction: row;

        .button.post-category {
          padding: 0.8em 1em !important;
        }

        .post-category {
          margin: 0 0.7em;
          border-radius: 6px;
          background: var(--background-light);
          display: flex;
          align-items: center;
          padding: 0.5em 1em !important;
          font-weight: bolder;

          &:hover {
            cursor: pointer;
            opacity: .85;
          }

          &:nth-child(1) {
            background: var(--my-message);
            margin-left: 0;
            color: var(--text-color) !important;
          }
        }
      }
    }

    strong {
      font-weight: 600;
    }

    blockquote {
      display: flex;

      &:after {
        content: none;
        margin: 0 .5em;
      }

      &:before {
        content: none;
        margin: 0 .5em;
      }
    }

    a {
      color: var(--green)
    }

    width: $width;
    margin: 0 auto;

    iframe {
      width: 100%;
      max-width: 100%;
      height: 28vw;
      border: none;
    }

    figure {
      text-align: center;
      max-width: 100%;

      .fluid-width-video-wrapper {
        padding: 0 !important;
      }

      img {
        max-width: 100%;
      }
    }

    &-header {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom:10px;
      background-position: center;
      background-size: cover;

      &-more {
        h3 {
          font-size: 24px;
        }
      }

      &__visual {
        &__message {
          min-height: 0px;
          .message {
            background-color: var(--green) !important;
            box-shadow: none !important;
        }
          .header {
            color: var(--text-color) !important;
            text-align: center;
          }
        }
        .title input{
          color: var(--text-color) !important;
        }
        .actions {
          position: inherit;
          float: right !important;
          /* right: 1rem; */
          /* top: 5rem; */
          display: inherit;
          margin: -10px;
          z-index: 99999;

          .button{
            margin: 10px;

            
            &.publish-button:disabled, &.draft-button:disabled, &.delete-button:disabled {
              border: 1px solid #2E2E2E !important;
              background: #2E2E2E !important;
            }

            &.publish-button {
              border: 1px solid var(--green);
              color: var(--back-color);
              font-weight: normal;
              background-color: var(--green);
            }
            &.publish-button:hover {
              color: var(--text-color);
              background: var(--green);
            }
            &.draft-button {
              border: 1px solid rgb(236, 173, 0);
              color: var(--back-color);
              font-weight: normal;
              background-color: rgb(236, 173, 0);
            }
            &.draft-button:hover {
              color: var(--text-color);
              background: rgb(236, 173, 0);
            }
            &.delete-button {
              border: 1px solid var(--red);
              color: var(--back-color);
              font-weight: normal;
              background-color: var(--red);
            }
            &.delete-button:hover {
              color: var(--text-color);
              background: var(--red);
            }
          }
        }
        input {
          width: 100%;
          color: #FFF !important;
          font-weight: bolder !important;
          background: transparent !important;
          border-color: transparent !important;
          padding: 0 !important;
        }
      }


      h3 {
        width: $width;
        text-align: left;
        font-size: 2.8em;
        font-family: 'Lexend', 'Lora', serif;

        @media #{$for-tablet-portrait-down} {
          font-size: 34px;
        }
      }
    }
  }

  
  .editor {
    min-width: 1040px;
    max-width: $width;
    margin: auto;
    .fr-top {
      position: sticky !important;
      z-index: 999;
    }
    .fr-toolbar{
      background: var(--green);
      border: 1px solid rgba(90, 97, 105, 0.2);
    }
    .fr-box.fr-basic .fr-wrapper {
      background: transparent;
      border: 1px solid rgba(90, 97, 105, 0.2);
      min-height: 9999px;
      margin-bottom: 400px;

      .fr-element.fr-view {
        color: var(--text-color) !important;
      }
    }
  }

  .post-date {
    font-size: 1.1em;
    b {
      font-weight: bolder;
    }
  }

  .comments-container {
    margin: 0 auto;
    width: $width;

    .ui.menu {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin: 1rem 0;
      font-family: 'Lexend', 'Lato', 'Helvetica Neue',Arial,Helvetica,sans-serif;
      background: var(--green);
      font-weight: 400;
      border-radius: .28571429rem;
      min-height: 2.85714286em;
    }

    .attached.menu{
      border-radius: 7px !important;
    }
    .ui.attached.menu:not(.tabular) {
      border: none;
      border-radius: 7px !important;
    }
    .ui.menu .active.item {
      background: rgba(0, 0, 0, 0.3);
      color: #fff;
      font-weight: 400;
      box-shadow: none;
    }
    .ui.attached.segment {
      border: none !important;
    }
  }

  .ratings {
    width: $width;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 1.2rem;
    padding: 3rem !important;
    border-top: solid 1px #31313a;
    border-bottom: solid 1px #31313a;
    

    .rating {
      max-width: 90px;
      margin-left: -5.5rem !important;
      padding-bottom: 1rem !important;

      i.icon {
        color: var(--text-color) !important;
        font-size: 2rem;
      }
    }
  }

  .tip {
    .amount {
      .button {
        background: var(--background-light);
        color: var(--text-color);
        &:hover {
          background: var(--green);
          color: var(--text-color);
        }
      }
      .active {
        background: var(--green);
        color: var(--text-color);
      }
    }
  }

  .tokens {
    width: $width;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bolder;
    >.ui.image {
      width: 1.5*$padding;
      height: auto;
      margin: 0 $padding * 0.5;
    }
  }


  .social-buttons-container {
    height: 5vh;
    border-radius: 5px;
    margin: 0 auto;
    width: $width;
    display: flex;
    justify-content: space-around;

    a:hover {
      color: var(--text-color) !important;
    }

    @media #{$for-phone-only} {
      display: none;
    }
  }

  .add-comment {
    width: 100%;
    margin: 0 auto;

    @media #{$for-tablet-portrait-down} {
      width: 90%
    }
  }
}

@media only screen and (max-width: 767px) {
  .row.sub-header.cz-post-header-top{
    .post-date{
      .post-author.cz-post-mobile-only{
        font-size: 12px;
      }
    } 
  } 

  .joinUsToday {
    float: left;
    width: 100%;
    
    .joinUs {
      width: 100%;
    }

    .registerWithUs{
      width: 100%;

      .joinFreeHold{
        .joinFree{
          width: 100%;
          margin-bottom: 10px;
        }

        .joinFreeText{
          width: 100%;
        }
      }
    }
  }
  
  .reportButton{
    width: auto;
    margin-top: 10px !important;
    background: transparent !important;
    color: #fff !important;
    cursor: pointer;
  }
  .cz-single-article-container {
    max-width: -webkit-fill-available;
    margin: 0 auto;
    background: #13131380;
    border-radius: 6px;
    .row.sub-header.cz-post-header-top .post-date .post-author button {
      .plusIcon{
        vertical-align: text-top;
      }
    }
  }
  
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation:portrait) {
  
  .cz-single-article-container {
    .row.cz-set-set{
      .social-buttons-container{
        div{
          margin: 0;
        }
      } 
    } 
    .row.cz-post-header-top-enter{
      .post{
        .author-post{
          .ui.grid{
            .row.post-preview{
              .six.wide.column.content-img{
                min-height: 400px;
              }
            } 
          } 
        } 
      } 
    } 
  }
}

@media #{$for-phone-only} {
  .sub-header.cz-post-header-top .post-header h1 {
    font-size: 28px !important;
    line-height: 32px;
  }

  .cz-single-article-container .row.sub-header.cz-post-header-top .post-header {
    padding: 25px 15px 5px !important;
  }

  .row.sub-header.cz-post-header-top .row .post-categories-n-tags {
    padding: 0 25px;
  }

  .row.sub-header.cz-post-header-top .post-date {
    padding: 0 0;
  }

  .row.sub-header.cz-post-header-top .post-date .post-author button {
    float: right !important;
    width: 100% !important;
    font-size: 14px;
    margin-right: 0 !important;
    margin-bottom: 15px !important;
  }

  .cz-single-article-container .row.cz-set-set .post{
    padding: 5px 15px 5px !important;
  }

  .cz-single-article-container .ratings{
    padding: 3rem 5px !important;
    .ratingSystem span.dot{
      margin: 0 5px;
    }
  }

  .cz-single-article-container{
    .row.cz-set-set{
      .social-buttons-container{
        padding: 0 15px !important;

        a{
          margin-right: 0.5em;
          float: left;
          margin-bottom: 0.5em;

          div{
            width: auto !important;
            border-radius: 8px;

            > div {
              margin: 0 !important;
            }
          } 
        }
      } 
    }

    .row.cz-post-header-top-enter{
      .post{
        .author-post{
          .post-preview{
            &:last-child{
              margin-bottom: 10px;
            }
          }
        }
      } 
    } 

    .cz-new-card{
      .ui.grid{
        .floated.three {
          position: absolute;
          right: 0;
        }
      } 
    } 

    .row.cz-post-comments{
      .comments-container {
        padding: 0 15px;

        .ui.attached.menu {
          margin-top: 0;
        }

        .ui.pagination.menu {
          width: 100%;
          text-align: center;
          align-items: center;
          display: inline-block;

          .item{
            margin: 0 3px;
          }
        }

        .ui.comments{
          .comment {
            padding: 20px 0 !important;

            .content{
              .form{
                margin-right: 0;
              }
            } 

            .avatar {
              // width: 4em;
              ~.content {
                // margin-left: 5.5em;
              }
            }
          }
        } 
        
        .add-comment {
          width: 100%;
        }
      }
    } 
  }
  
  
  @include styling($width: 90%);
  .post-author {
    width: 100%;
    margin: 0 auto;
    display: block !important;
    align-items: center;
    color: black !important;
    color: var(--text-color) !important;
  }
  .tokens {
    width: 50%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bolder;
    >.ui.image {
      width: 1.5*$padding;
      height: auto;
      margin: 0 $padding * 0.5;
    }
  }
  iframe {
    width: 100%;
    max-width: 100%;
    border: none;
  }
  .post {
    &__visual {
      .editor {
        min-width: 100%;
        margin: auto;
        .fr-top {
          position: sticky !important;
          z-index: 99999;
        }
        .fr-toolbar{
          background: var(--green);
          border: 1px solid rgba(90, 97, 105, 0.2);
        }
        .fr-box.fr-basic .fr-wrapper {
          background: transparent;
          border: 1px solid rgba(90, 97, 105, 0.2);
          min-height: 9999px;
          margin-bottom: 400px;
    
          .fr-element.fr-view {
            color: var(--text-color) !important;
          }
        }
      }
      .post-featured-image {
        position: relative;
        width: 100%;
        min-width: 100%;
        max-height: 700px;
        overflow: hidden;
        margin: auto;
        border-radius: 0 !important;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
  
        img {
          margin: 0 auto;
          width: 100%;
          margin: auto;
          transition: .5s ease;
        }
        .icon {
          margin: auto;
          width: 100%;
          position: absolute;
          top: 0;
          bottom: 0;
          color: #1a1a24;
          opacity: 0.3;
          transition: .2s ease;
          &:hover {
            opacity: 0.2;
            cursor: pointer;
          }
        }
      }
    }
  }
}


@media screen and (max-width: 850px){
  .cz-single-article-container .row.cz-set-set .social-buttons-container div {
    width: auto;
  }
  .cz-single-article-container .social-buttons-container a {
    margin: 0 0.5em 0.5em;
    font-weight: 300;
  }
}

@media #{$for-tablet-portrait-up} {
  @include styling($width: 75%);
}

@media #{$for-desktop-up} {
  @include styling($width: 60%);
}

@media #{$for-big-desktop-up} {
  @include styling($width: 70%);
}


.row.cz-post-header-top + div .post {
  background: #00000045;
  padding: 12px;
  color: white;
  font-weight: 200 !important;
}

.row.cz-post-header-top + div .post * {
  font-family: 'Lexend', 'Roboto Serif' !important;
}


.row.cz-post-header-top + div .post span.highlight {
  background: #0078d7 !important;
}

.row.cz-post-header-top + div .post span.highlight::selection {
  background: #0078d7; /* WebKit/Blink Browsers */
  color: #fff;
}
.row.cz-post-header-top + div .post span.highlight::-moz-selection {
  background: #0078d7; /* Gecko Browsers */
  color: #fff;
}

.row.cz-post-header-top + div .post span.highlightComment{
  background: #ffe826 !important;
  color: #1B1B1B !important;
}

.row.cz-post-header-top + div .post span.highlightComment span.highlight {
  background: #ffe826 !important;
  color: #1B1B1B !important;
}

.row.cz-post-header-top + div .post h2 {
  line-height: 40px;
}

.row.cz-post-header-top + div .post ol li,
.row.cz-post-header-top + div .post p,
.row.cz-post-header-top + div .post ul li {
    font-size: 16px!important;
    border-radius: 10px;
    word-break: break-word;
    font-weight: 300!important;
    color: #d1d1d1!important;
    line-height: 28px!important;
}

.row.cz-post-header-top + div .post blockquote{
  border-left: 2px solid #6d737b;
  margin-left: 1.5rem;
  padding-left: 1rem;
  margin: 2.5em 0 2.5em 2rem;
}

.row.cz-post-header-top + div .post blockquote p{
  font-size: 18px !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  line-height: 36px !important;
}

.row.cz-post-header-top + div .post p a {
  color: var(--pink);
}

.row.cz-post-header-top + div .post .cz-set-selection-text {
  background: #000 !important;
  display: inline-block;
  border-radius: 7px;
  position: absolute;
  top: -52px;
  display: none;
  width: 150px;
  text-align: center;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.row.cz-post-header-top + div .post .cz-set-selection-text::before {
  position: absolute;
  width: 20px;
  height: 20px;
  content: "";
  background: #000;
  border-radius: 5px;
  bottom: -8px;
  transform: rotate(45deg);
  left: 0;
  right: 0;
  margin: 0 auto;
}

.row.cz-post-header-top + div .post span.highlight .cz-set-selection-text::selection,
.row.cz-post-header-top + div .post span.highlight .cz-set-selection-text .spanul::selection,
.row.cz-post-header-top + div .post span.highlight .cz-set-selection-text .spanul .spanli::selection,
.row.cz-post-header-top + div .post span.highlight .cz-set-selection-text .spanul .spanli a::selection,
.row.cz-post-header-top + div .post span.highlight .cz-set-selection-text .spanul .spanli img::selection{
  background: unset !important;
}

.row.cz-post-header-top + div .post span.highlight .cz-set-selection-text::-moz-selection,
.row.cz-post-header-top + div .post span.highlight .cz-set-selection-text .spanul::-moz-selection,
.row.cz-post-header-top + div .post span.highlight .cz-set-selection-text .spanul .spanli::-moz-selection,
.row.cz-post-header-top + div .post span.highlight .cz-set-selection-text .spanul .spanli a::-moz-selection,
.row.cz-post-header-top + div .post span.highlight .cz-set-selection-text .spanul .spanli img::-moz-selection{
  background: unset !important;
}

.row.cz-post-header-top + div .post span.highlight  .cz-set-selection-text .spanul .spanli img::-moz-selection,
.row.cz-post-header-top + div .post span.highlight::selection .cz-set-selection-text  .spanul .spanli img::-moz-selectio{
  background: unset !important;
}

  .row.cz-post-header-top + div .post .cz-set-selection-text .spanul {
  padding: 0;
  margin: 0;
  position: relative;
  z-index: 123;
}

.row.cz-post-header-top + div .post .cz-set-selection-text .spanul .spanli {
  display: inline-block;
  padding: 7px 4px 10px;
}

.row.cz-post-header-top + div .post .cz-set-selection-text .spanul .spanli svg {
  color: #fff;
}

.row.cz-post-header-top + div .post .cz-set-selection-text .spanul .spanli img {
  width: 30px;
}

.row.cz-post-header-top + div .post .highlight{
  position: relative;
  // display: inline-block;
}

.row.cz-post-header-top + div .post .highlight:hover .cz-set-selection-text{
  display: inline-block;
}


.row.editor_canonical {
  display: flex;
  padding: 15px 0 5px;
}

.row.editor .actions{
  margin-bottom: 30px;
}

.bookmark-button{
  margin-left: 25px;
  margin-right: 5px;
  cursor: pointer;
}

.canonical-checkBox {
  margin-top: 1rem;
  .ui.checkbox  {
    label{
      color: white;
    }
  }
  .ui.checkbox input:focus~label {
      color: white;
  }
  .ui.checkbox input:checked ~ label:after{
    color: #c83e93 !important
  }
  .ui.checkbox input:checked ~ label:before {
    border-color: #c83e93 !important
  }

  .ui.checkbox label:before {
    border-color: #c83e93 !important
  }
}

.hoverClick{
  cursor: pointer;
}

.ratingSystem.toolTipPopover{
  position: relative;

  &:hover{
    .ratingPopoverHolder{
      display: block;
    }
  }

  .ratingPopoverHolder {
    display: none;
    background-color: #131618;
    text-align: left;
    padding: 10px;
    position: absolute;
    bottom: 19px;
    margin: 0 auto;
    left: 0;
    right: 0;
    max-width: 170px;
    padding-bottom: 20px;
    border-radius: 4px;
    z-index: 1234;

    &::after{
        position: absolute;
        width: 30px;
        height: 30px;
        background: #131618;
        bottom: 0;
        content: "";
        transform: rotate(45deg);
        left: 0;
        right: 0;
        margin: 0 auto;
        border-radius: 4px;
        z-index: 123;
    }
  
    h4{
      font-size: 12px;
      font-weight: 300;
      text-align: left;
      line-height: 14px;
      margin: 0;
    }
  
    .ratingPopoverHolderDiv{
      text-align: left !important;
      position: relative;
      z-index: 12345;
  
      span{
        font-size: 12px;
        line-height: 14px;
        font-weight: 300;
      }
  
      .user{
        opacity: 0.6;
      }
    }
  }
  

}


.markets.tokenView  {
  background: #0a0a19;
  background-image: url(/img/bg48095923.png);
  background-position: center;
  background-size: cover;
  background-repeat-x: no-repeat;
  height: 100vh;
  margin-top: 20px !important;

  .ui.container {
    max-width: 1450px !important;
  }

  a {
    text-decoration: none !important;
  }

  .gray {
    color: rgba(246, 245, 255, 0.6) !important;
  }

  .fw-4 {
    font-weight: 400;
  }
  .fw-5 {
    font-weight: 500;
  }

  .market_location {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 15px;

    padding-top: 50px;
    margin-left: 20px;
    margin-right: 20px;
    a {
      color: #787B9C;
    }
  }

  .chartHeader {
    display:grid;
    grid-template-columns: 2fr 1fr;
    grid-auto-rows: 1fr;
    //grid-column-gap: 25px;
    grid-row-gap: 5px;

    .tokenCard {
      margin-top: 30px;
      margin-left: 20px;
      margin-right: 20px;
      width: 100%;
      &.xl {
        width: 100%;
      }
      .ui.card {
        min-height: 100%;
        background: rgba(9, 8, 23, 0.7);
        backdrop-filter: blur(52px);
        border-radius: 12px;
        box-shadow: none;
        width: 100% !important;
        padding: 20px 0;
        .header,.meta,.content,.description {
          color: #fff !important;
        }

        .split {
          margin-top: 30px;
          p {
            display: flex;
            justify-content: space-between;
          }
        }

        .extra {
          ul {
            padding-left: 0;
          }
          .tag {
            display: inline-block;
            background: #25273A;
            border-radius: 8px;
            font-size: 12px !important;
            color: #999CB6;
            padding: 4px 6px;
            margin: 5px;
            list-style: none;
            &.more {
              background: rgba(105, 89, 230, 0.15);
              color: #6959E6;
              &:hover {
                cursor: pointer;
              }
            }
          }
          .split:first-child {
            margin-top: 0px;
          }
        }

        .content {
          .headerContent {
            display: flex;
            place-items: flex-start;
            justify-content: space-between;
            border-bottom: 1px solid #1D1C2F;
            padding-bottom: 20px;
            font-size: 18px;

            div:first-child {
              width: 50%;
            }
            .change {
              background: rgba(137, 245, 194, 0.1);
              color: #89F5C2;
              font-weight: 600;
              font-size: 12px;
              line-height: 15px;
              border-radius: 8px;
              padding: 7px 8px;
              &.up {
                background: rgba(137, 245, 194, 0.1);
                color: #89F5C2;
              }
              &.down {
                background: rgba(245, 137, 137, 0.1);
                color: #f58989;
              }
            }
          }
          .meta {
            color: rgba(246, 245, 255, 0.6) !important;
            font-size: 12px;
            .rank {
              background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(105.62deg, #FCCFF7 -0.85%, #E659D6 26.92%, #8877EA 56.49%, #86E2EE 85.63%), #D9D9D9;
              border-radius: 4px;
              padding: 2px 7px;
              margin-right: 5px;
              color: #fff;
            }
          }
          .description {
            padding-top: 20px;
            border-bottom: 1px solid #1D1C2F;
            padding-bottom: 30px;
            ul {
              padding-left: 0;
            }
            ul li {
              display: inline-flex;
              margin: 0 10px;
              list-style: none;
              margin: 0 10px;
              font-weight: 500;
              font-size: 12px !important;
              line-height: 18px;
              color: #787B9C;
              &:hover {
                cursor: pointer;
              }
              &.active {
                background: #25273A !important;
                color: #fff;
                border-radius: 8px;
                padding: 5px 10px;
              }
            }

            .percentProgress {
              background-color: #23223A;
              border-radius: 10px;
              height: 7px;
              .bar {
                background-color: #7AD6AE;
                border-radius: 10px;
                height: 7px;
              }
            }

            .item {
              padding: 15px 20px;
              span {
                margin-left: 15px;
              }
              i {
                writing-mode: vertical-lr;
              }
            }
            .ui.accordion {
              background: transparent;
              width: 100%;
              .title:not(.ui) {
                background: transparent !important;
                color: #fff !important;
                padding: 0 !important;
              }
              .socialLinks {
                margin: 20px 0;
                a {
                  display: block;
                  background:rgba(246, 245, 255, 0.11);
                  padding: 10px;
                  margin: 10px 5px;
                  text-align: center;
                  border-radius: 10px;
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
      button {
        background-color: transparent;
        border: 1px solid #1D1C2F;
        border-radius: 8px;
        padding: 10px 20px;
        margin: 0 10px;
        color: #fff;
      }
    }

  }

  .tokenHeader {
    display: grid;
    grid-template-columns: 2fr 3fr 2fr;
    grid-auto-rows: 1fr;
    grid-column-gap: 25px;
    grid-row-gap: 5px;

    .tokenCard {
      margin-top: 30px;
      margin-left: 20px;
      margin-right: 20px;
      width: 100%;
      &.xl {
        width: 100%;
      }
      .ui.card {
        min-height: 100%;
        background: rgba(9, 8, 23, 0.7);
        backdrop-filter: blur(52px);
        border-radius: 12px;
        box-shadow: none;
        width: 100% !important;
        padding: 20px 0;
        .header,.meta,.content,.description {
          color: #fff !important;
        }

        .split {
          margin-top: 30px;
          p {
            display: flex;
            justify-content: space-between;
          }
        }

        .extra {
          ul {
            padding-left: 0;
          }
          .tag {
            display: inline-block;
            background: #25273A;
            border-radius: 8px;
            font-size: 12px !important;
            color: #999CB6;
            padding: 4px 6px;
            margin: 5px;
            list-style: none;
            &.more {
              background: rgba(105, 89, 230, 0.15);
              color: #6959E6;
              &:hover {
                cursor: pointer;
              }
            }
          }
          .split:first-child {
            margin-top: 0px;
          }
        }

        .content {
          .headerContent {
            display: flex;
            place-items: flex-start;
            justify-content: space-between;
            border-bottom: 1px solid #1D1C2F;
            padding-bottom: 20px;
            font-size: 18px;

            div:first-child {
              width: 50%;
            }
            .change {
              background: rgba(137, 245, 194, 0.1);
              color: #89F5C2;
              font-weight: 600;
              font-size: 12px;
              line-height: 15px;
              border-radius: 8px;
              padding: 7px 8px;
              &.up {
                background: rgba(137, 245, 194, 0.1);
                color: #89F5C2;
              }
              &.down {
                background: rgba(245, 137, 137, 0.1);
                color: #f58989;
              }
            }
          }
          .meta {
            color: rgba(246, 245, 255, 0.6) !important;
            font-size: 12px;
            .rank {
              background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(105.62deg, #FCCFF7 -0.85%, #E659D6 26.92%, #8877EA 56.49%, #86E2EE 85.63%), #D9D9D9;
              border-radius: 4px;
              padding: 2px 7px;
              margin-right: 5px;
              color: #fff;
            }
          }
          .description {
            padding-top: 20px;
            border-bottom: 1px solid #1D1C2F;
            padding-bottom: 30px;
            ul {
              padding-left: 0;
            }
            ul li {
              display: inline-flex;
              margin: 0 10px;
              list-style: none;
              margin: 0 10px;
              font-weight: 500;
              font-size: 12px !important;
              line-height: 18px;
              color: #787B9C;
              &:hover {
                cursor: pointer;
              }
              &.active {
                background: #25273A !important;
                color: #fff;
                border-radius: 8px;
                padding: 5px 10px;
              }
            }

            .percentProgress {
              background-color: #23223A;
              border-radius: 10px;
              height: 7px;
              .bar {
                background-color: #7AD6AE;
                border-radius: 10px;
                height: 7px;
              }
            }

            .item {
              padding: 15px 20px;
              span {
                margin-left: 15px;
              }
              i {
                writing-mode: vertical-lr;
              }
            }
            .ui.accordion {
              background: transparent;
              width: 100%;
              .title:not(.ui) {
                background: transparent !important;
                color: #fff !important;
                padding: 0 !important;
              }
              .socialLinks {
                margin: 20px 0;
                a {
                  display: block;
                  background:rgba(246, 245, 255, 0.11);
                  padding: 10px;
                  margin: 10px 5px;
                  text-align: center;
                  border-radius: 10px;
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
      button {
        background-color: transparent;
        border: 1px solid #1D1C2F;
        border-radius: 8px;
        padding: 10px 20px;
        margin: 0 10px;
        color: #fff;
      }
    }
  }
}

@media (max-width: 1024px) {
  .markets.tokenView  {
    .tokenHeader {
      .tokenCard {
        margin-top: 100px;
        width: 50%;
        margin-left: 20px;
        margin-right: 20px;
        &.xl {
          width: 50%;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .markets.tokenView  {
    .ui.container {
      margin-left: 0 !important;
  }
    .tokenHeader {
      display: block;
      .tokenCard {
        margin-top: 100px !important;
        width: 100%;
        margin: auto;
        &.xl {
          width: 100%;
        }
      }
    }
  }
}

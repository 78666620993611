/* DEX Pgae css start */

main.homepage.dexpage h1.ui.header.headerContent {
  text-align: center;
}
main.homepage.dexpage .headerBtnBox.position-relative.trade-btns-cus {
  align-items: center;
  justify-content: center;
  margin-left: auto !important;
  margin-right: auto !important;
}
main.homepage.dexpage h1.ui.header.headerContent {
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center;
}
main.homepage.dexpage .headerBtnBox.position-relative.trade-btns-cus {
  margin-left: auto !important;
  margin-right: auto !important;
  display: flex;
  justify-content: center;
}
.trade-btns-cus {
  text-align: center;
  width: 100%!important;
  max-width: 100%!important;
  justify-content: center!important;
}
.trade-btns-cus button.ui.button.btn.headerBtn.px-3.mb-2.mb-lg-0 {
  width: 150px;
}
main.homepage.dexpage button.ui.button.btn.headerBtn.px-3.mb-2.mb-lg-0 {
  width: auto;
}
.trade-btns-cus button.ui.button.joinBtn {
  width: 150px!important;
  margin-left: 20px;
}
.dex-counter ul li {
  display: flex;
  width: auto;
  align-items: center;
}
.dex-counter ul li p {
  margin: 0;
  padding-left: 12px;
}
.dexcounter-sec {
  background-image: url(/img/angleImg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 360px;
  display: block;
  position: relative;
  margin-top: -150px;
  padding: 320px 0px 0px;
}
.decentralised-sec .ecoSystem {
  width: 100%;
  display: block;
}
.decentralised-sec .marketInner {
  background: #1e1a1d00;
  border-radius: 0px;
  padding: 0px;
}
.decentralised-sec .ecoSystem .column {
  margin: 0;
  padding: 0px!important;
}
.decentralised-sec .marketInner .ecoSystem {
  padding: 0px 0px 0px 150px;
}
.decentralised-sec .nftImg img.ui.image {
  position: relative;
  left: 10px;
  right: 0px;
  margin: 0px;
  width: 100%;
}
.dexpage .ui.grid.hero_section {
  padding-bottom: 0px;
}
.dexpage .homebg_section .hero_banner_area {
  min-height: 550px;
  height: 100%;
  background: none;
  top: 80px;
}
.earn-wrap {
  display: flex;
  position: relative;
  margin-bottom: 10px;
}
.earn-right-cont {
  position: relative;
  padding-left: 50px;
}
.earn-right-cont {
  position: relative;
  margin-left: 50px;
  margin-right: 50px;
  display: flex;
  background: #221721;
  border-radius: 20px;
  padding: 20px;
  height: auto;
  margin-bottom: 30px;
}
.number {
  margin-right: 10px;
}
.number p {
  background: linear-gradient(
90.24deg, #FA2CAB -1.6%, #9B69FC 99.79%);
  color: #fff;
  padding: 8px;
  border-radius: 30px;
  width: 40px;
  text-align: center;
  height: 40px;
}
.point-des h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 107.5%;
  letter-spacing: -0.015em;
  color: #fff;
}
.point-des p {
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;
display: flex;
align-items: center;
letter-spacing: 0.01em;
color: #FFFFFF;
}
.earn-img {
  margin-top: 40px;
}
.earn-right-cont-close {
  position: relative;
    margin-left: 50px;
    margin-right: 50px;
    display: flex;
    background: #22172100;
    border-radius: 20px;
    padding: 0px 20px;
    height: auto;
    margin-bottom: 40px;
    align-items: center;
}
.decentralised-sec {
  padding: 80px 0px;
}
.decentralised-sec button.ui.button.joinBtn {
  margin-left: 90px;
}
// .decentralised-sec .marketInner h2 {
//   position: relative;
// }
.decentralised-sec h2.bottomLine::before {
  position: absolute;
  content: '';
  right: 40%;
  width: 350px;
  height: 5px;
  background-image: url(/img/bottomLine.png);
  background-repeat: no-repeat;
  bottom: 12px;
}
.hero_banner_area.tradeTerraSection img.ui.image.line {
    display: block;
    float: right;
    margin-right: 40px;
}
.tradeTerra h5 {
  background: linear-gradient( 
16deg, #FA2CAB -1.6%, #9B69FC 99.79%), #FA2CAB;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.tradeTerra p {
  font-family: Lexend;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
  color: #FFFFFF;
  opacity: 0.8;
  margin-right: 0 !important;
}
.HeadingWrap {
  width: 100%;
  display: inline-block;
  margin: 15px 0;
}
.HeadingWrap h1.ui.header.headerContent {
  font-weight: 600;
  font-size: 46px;
  line-height: 107.5%;
  letter-spacing: -0.015em;
  text-align: left;
}
.tradeTerra {
  padding-left: 170px;
}
.partnerText h5 {
  background: linear-gradient( 
16deg, #FA2CAB -1.6%, #9B69FC 99.79%), #FA2CAB;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.partnerText h2 {
  font-family: Lexend;
  font-style: normal;
  font-weight: 600;
  font-size: 46px;
  line-height: 107.5%;
  letter-spacing: -0.015em;
  color: #FFFFFF;
}
.partnerText p {
  font-weight: 300;
  font-size: 18px !important;
  line-height: 25px !important;
  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
  color: #fff !important;
  opacity: 0.8;
}
.partnerText button.ui.button.joinBtn img.ui.image {
  margin-left: 10px;
  transition: 0.9s all ease;
}
.partnerText button.ui.button.joinBtn {
  width: auto !important;
  transition: 0.9s all ease;
}
.partnerText button.ui.button.joinBtn:hover img.ui.image {
  margin-left: 20px;
  transition: 0.9s all ease;
}
.partnerText img.ui.image.line {
  margin: 0;
}
.partnerText ul li {
  font-style: normal;
  font-weight: 300;
  font-size: 18px !important;
  line-height: 25px;
  letter-spacing: 0.005em;
  color: #FFFFFF;
  opacity: 0.8;
}
.ourPartnerSection {
  margin: 80px 0;
  display: inline-block;
  width: 100%;
}
.ExtensionSection .partnerText .HeadingWrap img.line {
  position: absolute;
  top: 62px;
}
.exchangeContent button.ui.button.btn.headerBtn {
  width: auto;
}
.marketInner .exchangeContent h2 {
  font-weight: 600;
  font-size: 46px;
  line-height: 107.5%;
  letter-spacing: -0.015em;
  margin-bottom: 30px;
}
.exchangeContent img.bottomLine {
  position: absolute;
  right: 80px;
  top: 50px;
  max-width: 310px;
}
.exchangeSection .marketInner {
  padding: 50px;
}
.exchimgRight .exchimg {
  display: flex;
  justify-content: flex-end;
}
.exchangeSection .marketInner p {
  font-weight: 300;
}
.decentralised-sec .marketInner .ExchangeHeading h2 {
  font-size: 46px;
}
.ExchangeHeading .bottomLine+p {
  max-width: 420px;
}
.HeadingWrap.CommunityText img.ui.image.line {
  max-width: 255px;
}
.decentralised-sec .ExchangeHeading h2.bottomLine::before {
  right: inherit;
  width: 310px;
  left: 0px;
  background-size: contain;
}
.BestTerra .partnerText h5 {
  background: linear-gradient( 
16deg, #FA2CAB -1.6%, #9B69FC 40.79%), #FA2CAB;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.BestTerra .partnerText button.ui.button.joinBtn {
  text-transform: inherit !important;
}
.bg-gradient-loop.community_Tab.partners.community_dex.DEXSection#DEX {
  background-image: url(/dex_img.png);
}
a.learnMore:hover img {
  margin-left: 25px;
  transition: 0.5s all ease;
}
.learnMore img {
  transition: 0.5s all ease;
}
.tradeTerra span.ImgBtm {
  display: inline-block !important;
}
span.ImgBtm.Big::before {
  background-image: url(/img/bottomLine.png);
  background-repeat: no-repeat;
  bottom: -5px;
  content: '';
  position: absolute;
  width: 315px;
  height: 5px;
  background-size: contain;
}
main.homepage.dexpage .hero_banner_area.tradeTerraSection {
  padding-top: 0;
}
.bg-gradient-loop.community_Tab.partners.community_dex.decentralised-sec.DEXSection#DEX {
  padding-bottom: 80px;
}
main.homepage.dexpage .hero_banner_area.tradeTerraSection .ui.grid.hero_section {
  padding-top: 0;
}
.wallet .ImgBtm::before {
  left: 10px;
  width: 165px;
  height: 5px;
  background-image: url(/img/btmLine.png);
}
.bg-gradient-loop.ExpandSection {
  margin-top: 170px;
}
h1.ui.header.headerContent span.firstAmm {
  text-transform: none;
}

/* DEX Pgae css End */

/* jk Css End */

@media only screen and (max-width: 1250px) {
#mainNav .navbar-nav .nav-item .nav-link {
    font-size: 15px;
}  
}
@media only screen and (max-width: 1199px) {
h1.ui.header.headerContent {
  font-size: 50px;
}
.hero_banner_area .ui.list .item a {
  font-size: 16px;
}
button.ui.button.btn.headerBtn {
  font-size: 16px;
  height: 50px;
}
.app-body .topIcons h3 {
  font-size: 20px;
}
// .app-body .imgTxt h5 {
//   font-size: 14px;
//   line-height: 18px;
// }
.app-body .rankHigh h5, .app-body .homepage .rankHigh p {
  font-size: 16px;
  line-height: 20px;
}
// .communityBox h2, .marketInner h2, .partnerText h2, .HeadingWrap h1.ui.header.headerContent {
//   font-size: 28px !important;
//   line-height: 100%;
//   margin-left: 0px;
//   margin-top: 0;
// }
// .communityBox {
//   display: flex;
//   flex-direction: column;
//   height: 100%;
//   justify-content: space-between;
// }
// .communityBox.extensionInner button.ui.button.btn.headerBtn.px-3 {
//   width: fit-content;
// }
// .roadMapOuter {
//   margin-top: 150px;
// }
// .titleWrap {
//   margin-bottom: 75px;
// }
// .hero_banner_area .ui.grid.hero_section {
//   padding-bottom: 100px;
// }
// .productSection h2 {
//   font-size: 36px;
//   max-width: 470px;
// }
.loop-text h2 {
  font-size: 36px !important;
  line-height: 40px !important;
}
#mainNav .navbar-nav .nav-item .nav-link {
  font-size: 14px;
  margin-right: 5px !important;
}
.main_action_btn.cz-home-set-icons {
  margin-top: 0;
}
span.articleCount {
  padding-right: 50px;
}
.dexpage .homebg_section .hero_banner_area {
  min-height: 350px;
}
.dexcounter-sec {
  height: 250px;
  padding: 200px 0px 0px;
  margin-top: -100px;
}
.counterWrap.dex-counter {
  margin: 0 50px;
}
.decentralised-sec h2.bottomLine::before {
  right: inherit;
  left: 0;
  max-width: 250px;
  width: 100%;
  background-size: contain;
}
.hero_banner_area.tradeTerraSection img.ui.image.line {
  float: left;
}
.partnerText p, .tradeTerra p, .partnerText ul li {
  font-size: 16px !important;
}
.ExtensionSection .partnerText .HeadingWrap img.line {
  top: 40px;
}
.exchangeContent img.bottomLine {
  top: 30px;
  max-width: 220px;
}
.hero_banner_area.tradeTerraSection .ui.grid.hero_section {
  padding-bottom: 50px;
}
.ourPartnerSection {
  margin: 25px 0;
}
main.homepage.dexpage .hero_banner_area .ui.grid.hero_section {
  padding-bottom: 0px;
}
.point-des p {
  font-size: 14px;
  line-height: 20px;
}
.point-des h3 {
  font-size: 20px;
}
.earn-right-cont {
  margin-left: 30px;
  padding: 15px;
}
.tradeTerra {
  padding-left: 50px;
}
.wallet .ImgBtm::before {
  left: 2px;
  width: 120px;
}
.bg-gradient-loop.ExpandSection {
  margin-top: 80px;
}
.dexpage .homebg_section .hero_banner_area {
  top: 30px;
}

}

@media only screen and (max-width: 1150px) {
// .descInner.listing li, .dateyearLeft.rightDesc ul li {
//   font-size: 14px !important;
// }
// .descRight.launch.mb-50 {
//   margin-bottom: 90px;
// }
// .select::after {
//   right: -60px;
// }
}
@media only screen and (max-width: 1100px) {
.select::after {
  right: -56px;
}
.dateyearLeft::after, .unselect .dateyearLeft::after {
  right: -65px;
}
#mainNav .navbar-nav .nav-item .nav-link span {
  font-size: 13px;
}

}

@media only screen and (max-width: 1080px) {
#mainNav .navbar-nav .nav-item .nav-link span {
  padding: 5px !important;
}
.select::after {
  right: -12%;
}
.dateyearLeft::after, .unselect .dateyearLeft::after {
  right: -62px;
}
span.articleCount {
  padding-right: 20px;
}
}

@media (max-width: 1024px) {
.dateyearLeft.rightDesc ul li, .descInner.listing li {
  font-size: 12px !important;
}
span.articleCount {
  padding-right: 50px;
}
.exchangeContent img.bottomLine {
  right: inherit;
  left: 20px;
}
// .counterWrap ul li::after {
//   right: -20%;
// }
.sidebar-main .cz-expanded-items .item {
  padding: 5px 7.5px !important;
}
}

@media (max-width: 992px) {
  .integrationTab .integrationImg{display: none;}
  .appDownloadSection{    padding-bottom: 0;}
  section.bg-gradient-loop.appDownload {padding-top: 0;}
  .col-sm-12.col-md-12.col-lg-6.pt-4.frameYield {margin-top: 3em;}
  .community_Tab.partners .innerboxx{    margin-top: 4em;}
  section.bg-gradient-loop.community_Tab.partners{padding-bottom: 0;}
  .loopwindow{padding-bottom: 0;}
  .analitycsText {margin-top: 50px;margin-bottom: 0px;}
  .analitycsText span{display: none;}
  section{border: none;}
  .analitycsText .innerBox {padding: 30px 0px;}
  .masthead .col-lg-10.offset-md-1.text-center img.line{width: 100%;}
  .homebg{width: 100%;}
  .column.signupBox:last-child .mobileSignUpBox {margin-top: 50px !important; }
  .ui.grid.loopsignup { padding: 10px 0; }
  h1.ui.header.headerContent {
    font-size: 34px;
}

.dateyearLeft::after, .unselect .dateyearLeft::after {
  right: -60px;
}
// .communityBox {
//   padding: 30px 20px 0 20px;
// }
.descInner.listing ul {
  padding-left: 0;
}
span.articleCount {
  padding-right: 60px;
}
.bg-gradient-loop.feedback_wrap .loop-text {
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}
.decentralised-sec .marketInner .ecoSystem {
  padding: 0px 0px 0px 50px;
}
.hero_banner_area.tradeTerraSection {
  padding-top: 0px;
}

}

@media (max-width: 991px) {
.partnerSection .ui.grid>.row>[class*="four wide"].column,
.partnerSection .ui.grid>.column.row>[class*="twelve wide"].column {
  width: 100% !important;
  text-align: center;
}
.loop-text {
  text-align: center;
}
.partnerSection .titleWrap h2 {
  text-align: center;
}
.counterWrap ul li p {
  font-weight: 300;
  font-size: 10px;
}
.dexcounter-sec div#shiva {
  font-size: 18px;
  line-height: 20px;
}
}

@media (max-width: 920px) {
.dateyearLeft::after, .unselect .dateyearLeft::after {
    right: -55px;
}
.descInner.listing {
  padding: 25px;
}
.dateyearLeft.rightDesc {
  padding: 20px;
}
.dateRightWrap.qfour.mb-110 {
  margin-bottom: 90px;
}
span.articleCount {
  padding-right: 40px;
}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
 .homepage .bg-gradient-loop.feedback_wrap .ui.grid>.row>[class*="twelve wide tablet"].column {
    width: 100%!important;
}
}
@media (max-width: 850px) {
.descInner.listing {
  padding: 24px;
}
.dateLeftWrap .dateyearLeft.rightDesc p {
  font-size: 14px !important;
}
.descInner.listing ul {
  padding-left: 10px;
}
.descInner.listing li {
  line-height: 22px;
}
span.articleCount {
  padding-right: 10px;
}
.app-body .iconsInner {
  width: 96%;
}
.ui.grid>[class*="two column"].row>.column.homeCounter {
  width: 100%!important;
}
// .counterWrap ul li::after {
//   right: -60%;
// }
.bg-gradient-loop.ExpandSection {
  margin-top: 40px;
}
}

@media (max-width: 800px) {
.dateyearLeft::after, .unselect .dateyearLeft::after {
  right: -50px;
}
}

@media (max-width: 768px) {
.homepage .homebg_section .hero_banner_area {
  min-height: auto;
}
.app-body .homepage  .ui.grid {
  display: flex;
  justify-content: space-around;
}
// .nftImg img.ui.image {
//   left: 0px;
// }
// .communityBox {
//   padding: 20px 20px 0 20px;
// }
.descInner.listing li {
  line-height: 18px;
}
}

@media (max-width: 767px) {
.hero_banner_area .ui.grid>[class*="two column"].row>.column {
  width: 100% !important;
}
.app-body .iconsInner {
  float: none;
  margin: 40px auto 0;
}
.ecoSystem.ui.grid>[class*="two column"].row>.column {
  width: 100% !important;
  margin: 15px 0;
}
.nftImg {
  display: flex;
  justify-content: flex-end;
}
.productSection .ecoSystem {
  padding-top: 20px;
}
.roadMapWrap.roadMapDesktop {
  display: none;
}
.roadMapMobile {
  display: block;
}
.column.nftImgRight {
  margin-bottom: 0px !important;
}
.titleWrap h2 {
  font-size: 30px;
}
.titleWrap p {
  font-size: 14px;
}
.blockText {
  padding: 10px;
}
.blockText h3 {
  font-size: 20px;
}
.row.join-community .blockText p {
  font-size: 14px;
  line-height: 20px;
}
.row.join-community h2, .appDownload h2, .bg-gradient-loop.team-section h2.ui.header {
  font-size: 36px;
}
h2.bottomImg::after {
  left: 0;
  right: 0;
  margin: auto;
}
.partnerSection {
  margin-bottom: 50px;
}
.dexpage .hero_banner_area .ui.list {
  margin-bottom: 0px;
}
.point-des h3 {
  font-size: 16px;
}
.earn-right-cont-close {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.earn-img img.ui.image {
  height: 280px;
}
.decentralised-sec {
  padding: 30px 0px;
}
main.homepage.dexpage .hero_banner_area .ui.grid.hero_section {
  padding-top: 0px;
}
.ourPartnerSection .ui.grid>[class*="two column"].row>.column {
  width: 100%!important;
}
.ourPartnerSection .ui.grid>[class*="two column"].row>.column:last-child,
.ourPartnerSection.ExtensionSection  .ui.grid>[class*="two column"].row>.column:first-child  {
  order: 1;
}
.ourPartnerSection .ui.grid>[class*="two column"].row>.column:first-child,
.ourPartnerSection.ExtensionSection  .ui.grid>[class*="two column"].row>.column:last-child  {
  order: 2;
}
.marketSection.exchangeSection .nftImg {
  justify-content: center;
}
.exchangeSection .marketInner {
  padding: 20px;
}
.tradeTerra {
  padding-left: 0;
}
.bg-gradient-loop.community_Tab.partners.community_dex.DEXSection#DEX {
  background-image: none;
}
// .counterWrap ul li::after {
//   right: -50%;
// }
}

@media screen and (max-width: 600px) {
.homebg_section .hero_banner_area {
    background-image: none;
}
h2.bottomImg::after {
  width: 250px;
}
.ui.grid>[class*="three column"].join-community.start-learning.row>.column {
  width: 100% !important;
}
.blockWrap img.ui.image {
  width: 100%;
}
.blockWrap .blockText img.ui.image {
  width: auto;
}
.blockWrap {
  height: auto;
  margin: 15px 0;
}
.partnerSection .ui.grid>[class*="three column"].row>.column {
  width: 100% !important;
}
.partnerSection .ui.segment {
  margin: 10px 0 !important;
}
.partnerSection .ui.grid > [class*="three column"].row > .column .ui.segment:last-child {
  margin-bottom: 0px !important;
}
// .counterWrap ul li p, .counterWrap ul {
//   display: block;
// }
// .counterWrap ul li {
//   display: inline-block;
//   width: 49%;
//   text-align: center;
//   margin-bottom: 20px;
// }
div#shiva {
  display: block;
  text-align: center;
  margin-bottom: 10px;
}
// .counterWrap ul li::after {
//   right: 0px;
// }

.loop-text h2,  .bg-gradient-loop.team-section h2.ui.header {
  font-size: 28px !important;
  line-height: 40px !important;
}
.bg-gradient-loop.footer_wrap .tesi-design {
  padding: 40px 15px !important;
  margin: 0px;
}
.dexcounter-sec {
  height: auto;
  padding: 150px 0px 0px;
}
.dexpage .homebg_section .hero_banner_area {
  padding-bottom: 0px;
}
.partnerText {
  padding-top: 30px;
}
.partnerText .HeadingWrap {
  position: relative;
}
.ExtensionSection .partnerText .HeadingWrap img.line {
  top: 25px;
}
}

@media only screen and (max-width: 480px) {
  button.ui.button.joinBtn {
    font-size: 12px !important;
} 
.groupBtns button.ui.button.joinBtn {
  width: 100px !important;
} 
.groupBtns button.ui.button.joinBtn.quest {
  width: 120px !important;
}
.groupBtns button.ui.button.joinBtn.learnBtn {
  width: 80px !important;
}
// .app-body main.homepage .ui.container {
//   padding: 0px;
// }
.app-body .rankHigh h5, .app-body .homepage .rankHigh p {
  font-size: 12px;
}
.iconTxtWrp img {
  width: 30px;
}
.hero_banner_area .ui.grid.hero_section {
  padding-bottom: 0px;
}
.productSection h2 {
  font-size: 24px;
}
// .communityBox h2, .marketInner h2 {
//   font-size: 22px !important;
// }
.AppSection .marketInner {
  padding: 10px 10px 0 10px;
}
.imgTxt p, .app-body .imgTxt h5 {
  font-size: 12px;
}
h1.ui.header.headerContent {
  font-size: 26px;
  text-align: center;
  letter-spacing: normal;
}
.productSection .ecoSystem {
  padding-top: 0px;
}
.monthContent ul {
  padding-left: 12px;
  margin-bottom: 0px;
}
.monthContent ul li {
  font-size: 12px !important;
  line-height: 18px !important;
}
.monthdate {
  padding: 10px;
}
.dateTxtInner {
  padding: 10px;
}
.monthContent p {
  font-size: 12px !important;
  text-align: left;
}
.dateTxt {
  width: 95%;
  display: inline-block;
}
.hero_banner_area button.ui.button.btn.headerBtn.px-3.mb-2.mb-lg-0 {
  width: auto !important;
}
h2.bottomLine::before {
  width: 230px;;
}
.marketInner {
  padding: 10px 0 10px 10px;
}
// .communityBox {
//   padding: 20px 10px 0 10px;
// }
// .groupBtns button.ui.button.joinBtn {
//   margin-right: 5px;
// }
// .seedInner {
//   padding-right: 10px;
// }
// .homepage .communityBox p, .marketInner p {
//   font-size: 14px;
//   line-height: 18px;
// }
// .roadMapOuter {
//   margin-top: 50px;
// }
.titleWrap {
  margin-bottom: 20px;
}
.community_Tab h2.ui.header, .appDownload h2, .financial_freedom h2.ui.header.integrationTabH2, .row.join-community h2, .row.exchange-section h2, .loop-text h2, .row.newsletter-section h2, .row.support-section h2,
.partnerSection .titleWrap h2 {
  font-size: 24px !important;
}
.sidebarVisible.sidebar-wrapper .sidebar.menu .row .link.list a {
  padding: 5px 7.5px !important;
}
.sidebar-wrapper .ui.segment.pushable .sidebar a, .sidebar-wrapper .ui.segment.pushable .sidebar button {
  font-size: 14px !important;
}
.ourPartnerSection {
  margin: 15px 0;
}
.exchangeContent img.bottomLine {
  top: 22px;
}
.exchangeSection .marketInner {
  padding: 10px;
}
.decentralised-sec .marketInner .ecoSystem {
  padding: 0px 15px;
}
.earn-img img.ui.image {
   height: auto; 
}
.dexcounter-sec {
  background-size: contain;
  background-position: top center;
}
.dexcounter-sec {
  padding: 80px 0px 0px;
  margin: -60px 0 0;
}
.app-body .iconsInner {
  width: 90%;
}
.app-body .tradeTerraSection .iconsInner {
  float: none;
}
}


@media (min-width: 576px) {
  .masthead .masthead-device-mockup .circle {
    width: 70%;
    display: block;
  }
  .masthead .masthead-device-mockup .device-wrapper {
    max-width: 12.5rem;
  }
}
@media (min-width: 768px) {
  .masthead .masthead-device-mockup .circle {
    width: 60%;
  }
  .masthead .masthead-device-mockup .device-wrapper {
    max-width: 15rem;
  }
}
@media (min-width: 992px) {
  .masthead .masthead-device-mockup .circle {
    width: 90%;
  }
  .masthead .masthead-device-mockup .device-wrapper {
    max-width: 15rem;
  }
  .masthead {height: 100vh;}
}
@media (min-width: 1200px) {
  .masthead .masthead-device-mockup .circle {
    width: 75%;
  }
  .masthead .masthead-device-mockup .device-wrapper {
    max-width: 15rem;
  }
}
@media (min-width: 1400px) {
  .masthead .masthead-device-mockup .circle {
    width: 70%;
  }
  .masthead .masthead-device-mockup .device-wrapper {
    max-width: 17rem;
  }
}

.swiper-container{
  background: #130611 !important;
}
.swiper-full-mobile {
  margin: 0px auto !important;
}

.mobileApp .swiper-slide img{
  border-radius:12px !important;
} 
 
.team-slider .swiper-full-mobile .swiper-slide img {
  transform: scale(0.8) ;
  opacity: 1 ;
  margin-top:315px;
}

.team-slider .swiper-full-mobile .swiper-slide.swiper-slide-prev img {
  transform: scale(1) !important;
  margin-top:40px !important;
}
.team-slider .swiper-full-mobile .swiper-slide.swiper-slide-next img {
  transform: scale(1) !important;
  margin-top:40px !important;
}
.team-slider .swiper-slide.swiper-slide-active img {
  transform: scale(1);
  margin-top:0 !important;
}
.team-slider .swiper-container.swiper-full-mobile .swiper-button-prev, .team-slider .swiper-container.swiper-full-mobile .swiper-button-next {
  top: 100px;
  left: 37%;
  width: 38px;
  height: 38px;
  padding: 0;
  background-size: contain;
  border-radius: 100px;
  background-position: center;
  background: linear-gradient(
151.28deg
, rgba(227, 227, 227, 0.3) 0%, rgba(0, 0, 0, 0.1) 100%);
  border: 1px solid #423441;
}

.team-slider .swiper-container.swiper-full-mobile .swiper-button-next {
  left: auto;
  right: 37%;
}

.team-slider .swiper-container.swiper-full-mobile .swiper-button-prev:after, .team-slider .swiper-container.swiper-full-mobile .swiper-button-next:after {
  color: white;
  font-size: 14px;
}
.tutorialLink{
  text-decoration: none;
}


@media(max-width:350px){
  .community_Tab.partners.performers_wrap .markets_widget .portfolio-table-row:first-child td .icon-reading::after {
    right: -12px;
  }
  .community_Tab.partners.performers_wrap .markets_widget .portfolio-table-row:first-child td .icon-box::before {
    left: -12px;
  }
  .modal.display-block.modal-team .modal-main > div * {
    transform: scale(0.95) translate(-1.2%);
  }
}

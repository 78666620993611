.btn.addTokenBtn{
  background: linear-gradient(90.24deg, #FA2CAB -1.6%, #9B69FC 99.79%), #FA2CAB;
  border-radius: 5px !important;
  color: #fff !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  text-transform: uppercase !important;
  width: 100px;
  height: 35px;
  padding: 5px 15px !important;
  border: 0;
  &:hover {
    background: #FA2CAB;
  }
}  



body .staking-table.token-table-cz tr td {
	padding: 8px 10px !important;
}
body .markets {
	margin-bottom: 100px !important;
}
.cz-tokens-mobile > div > div:last-child {
	border-radius: 0 0 10px 10px;
}
.cz-tokens-mobile > div > div .cz-tokens-mobile-td.cz-left-td:nth-child(2) button {
	width: auto;
}
.BtnTrade {
  clear: both;
  width: 100%;
}
.labeling {
  padding: 0 !important;
}
.staking-table.token-table-cz tr td div p {
  width: 130px;
}

.staking-table.token-table-cz tr td div p {
  white-space: nowrap;
}
.rankHigh {
  text-align: right;
}
.marketUp {
  color: #32FE9A !important;
  padding-left: 10px;
  position: relative;
}
.marketUp::before {
  position: relative;
  content: '';
  float: left;
  display: block;
  height: 10px;
  width: 10px;
  position: absolute;
  top: 6px;
    left: 0;
}

@media(max-width:1024px){
  .marketUp::before {
    top: 6px;
  }
  .cz-tokens-mobile-tr a {
    display: none;
}

.markets.staking-table.token-table-cz {border-bottom: 1px solid #3e3e3e;border-radius: 0;}

.markets.staking-table.token-table-cz h3 {
    padding-bottom: 0;
}

.cz-tokens-mobile-tr .markets span {
    padding-bottom: 12px;
}

.cz-tokens-mobile-tr  p {
    white-space: normal !important;
}
}


@media(max-width:600px){
  body .cz-tokens-mobile-td .ui.grid img.ui.image {
    width: 30px !important;left: 5px;
  }
  .cz-tokens-mobile-td .ui.grid {
    padding: 0px 0 5px 40px !important;
  }
  .cz-tokens-mobile-tr .markets span{padding-bottom:10px;}

}
.ui.modal.transition.visible.active.marketModal {
  top: 50% !important;
  border-radius: 0 0 0.5rem 0.5rem !important;
  background: #1B1B1B !important;
  border-radius: 10px !important;
  height: auto;

  .header {
    background-color: #100F1E !important;
    color: #fff !important;
  }

  .content {
    background-color: #181727 !important;
    color: #fff !important;
  }
}
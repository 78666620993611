::-webkit-input-placeholder { /* Edge */
  color: #fff !important;
  opacity: 1;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #fff !important;
  opacity: 1;
}

::placeholder {
  color: #fff !important;
  opacity: 1;
}
.search-inputWrap {
  min-height: 300px;
  //margin: 150px auto 100px;
  text-align: center;

  .pre_loader_grid {
    display: block !important;
    align-items: left;
  }
}
.searchInner {
  z-index: 99999;
  //max-width: 1100px;
  margin: auto;
  background: #1b1b1b;
  padding: 15px;
  //border-radius: 10px 10px 0 0;
  .react-autosuggest__suggestion {
    cursor: pointer;
  }
  .ui.selection.active.dropdown .menu {
    z-index: 100;
  }
}
.my-feeds-loader {
  .ui.active.dimmer {
    //z-index: -9999;
  }
}
.searchInner .ui.input {
  width: calc(100% - 340px);
}
.searchIcon {
  background: linear-gradient(90.24deg, #FA2CAB -1.6%, #9B69FC 99.79%), #FA2CAB;
  border: none;
  color: #fff;
  padding: 9px 31px !important;
  border-radius: .28571429rem;
}
.searchInner .ui.input input  {
  background: #131313;
  color: #fff;
}
.ui.selection.dropdown {
  background: #FA2CAB !important; 
  color: #fff;
  border: 1px solid #FA2CAB;
  margin-right: 10px;
}
.searchInner  .ui.active.search.dropdown input.search:focus + .text {
  color: #fff !important;
}
.row.post-preview {
  flex-direction: row-reverse !important;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 0 15px #1e1d35;
  overflow: hidden;
  background: transparent;
  border-radius: 0 !important;
  text-align: left !important;

  //width: 1100px;
  //margin: 0 auto 30px;
  .post-preview-info {
    width: 60% !important;
    /* margin: auto; */
  }
}
.searchInner  .post-preview-info .middle.aligned.row {
  text-align: left;
}
.row.post-preview .content-img {
    //background-image: url(https://old.trybe.one/wp-content/uploads/2021/11/Screenshot-2021-10-15-17.42.10-5.png);
    height: auto!important;
    display: inline-flex;
    width: 50% !important;
    float: right;
    background-size: cover;
}
.post-preview a.ui.grid.noselect {
  width: 100%;
}
.eight.wide.column.post-preview-info.cz-new-card {
  width: 50%;
}
.react-autosuggest__container input.react-autosuggest__input {
  width: 98%;
  background: #131313;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
}
.react-autosuggest__container {
  width: calc(100% - 340px);
  position: relative;
  font-weight: 400;
  font-style: normal;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: rgba(0,0,0,.87);
}
div#react-autowhatever-1 {


}
div#react-autowhatever-1  ul{ 
  list-style: none;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 200px;
  background: #fff;
  width: 100%;
  top: 40px;
  border-radius: 10px;
  color: #333;
  text-align: left;
  overflow-y: auto;    
  z-index: 11;  
  padding-left: 0px;
}
div#react-autowhatever-1  ul li {
  text-align: left;
  padding: 7px 20px; 
}
div#react-autowhatever-1  ul li:hover {
  background-color: #f3f3f3;
}
.noFound {
  //max-width: 1100px;
  margin: auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  font-size: 18px;
  color: #FA2CAB;
}
.search-inputWrap .swiper-container.swiper-container-initialized {
  background: transparent !important;
}
.search-inputWrap .ui.selection.dropdown, .search-inputWrap button.searchIcon {
  background: #0998B9 !important;
  border-color: #0998B9;
  color: #1B1B1B;
}



/* Media Query start */
@media only screen and (max-width: 1280px) {
.search-inputWrap  .row.post-preview {
  width: 100%;
  max-width: 850px;
}

}


@media only screen and (max-width: 900px) {
  .searchInner, .search-inputWrap  .row.post-preview  {
    max-width: 95%;
  }

}

@media only screen and (max-width: 768px) {
  .search-inputWrap  .row.post-preview {
    width: 100%;
  }
}

@media only screen and (max-width: 650px) {
.search-inputWrap .react-autosuggest__container {
  width: 100%;
  margin-bottom: 15px;
}
button.searchIcon, .ui.selection.dropdown {
  width: calc(50% - 5px);
}
.search-inputWrap .row.post-preview, .search-inputWrap  .row.post-preview .content-img {
  width: 100%;
  max-width: 100%;
} 
 
}

@media only screen and (max-width: 575px) {
  button.searchIcon, .ui.selection.dropdown {
    width: calc(50% - 10px);
  }
  .search-inputWrap a.ui.grid.noselect {
      padding: 15px 0 !important;
  }
  
}

@media only screen and (max-width: 480px) {
button.searchIcon, .ui.selection.dropdown {
  width: 100%;
  margin-right: 0;
  margin-bottom: 10px;
}
.search-inputWrap {
  margin: 90px auto 30px;
}
}

.search-inputWrap .my-feeds-loader svg {
  //width: 1100px !important;
}

.search-inputWrap .ui.segment.my-feeds-loader {
  //width: 1100px;
  margin-left: auto;
  margin-right: auto;
}